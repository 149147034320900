import axios from 'axios';
import React from 'react';
//import { Button } from '@material-ui/core';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_ERROR, ALERT_VARIANT_INFO } from '../helpers/alert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	buttonPrimary: {
		color: '#fff',
		background: '#0081f2',
		paddingLeft: '2px',
		paddingRight: '2px'
	}
}));

export const DownloadFile = (props) => {
	const [baseRef] = React.useState(React.createRef());
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const { label, request } = props;
	const buttonText = label ? label : '';

	const responseHandler = (response) => {
		let blob = new Blob([response.data], { type: response.headers['content-type'] });
		let ref = baseRef;
		ref.current.href = URL.createObjectURL(blob);
		ref.current.download = request.filename;
		ref.current.target = '_blank';
		ref.current.click();
	};

	const downloadFileFromServer = (request) => {
		try {
			enqueueSnackbar('downloading file ' + request.filename, ALERT_VARIANT_INFO);

			if (!request.action) {
				request.action = 'GET';
			}

			if (request.action === 'POST') {
				if (!request.data) {
					request.data = {};
				}
			}

			// console.log('downloading ', request.url);
			const headers = {
				headers: {
					...request.httpHeaders
				},
				responseType: 'blob'
				// 'Content-Type': 'application/octet-stream'
			};

			switch (request.action) {
				case 'GET':
				default:
					axios.get(request.url, headers).then(responseHandler);
					break;
				case 'POST':
					axios.post(request.url, request.data, headers).then(responseHandler);
					break;
			}

			// axios.post(request.url, request.data, headers).then((response) => {
			// 	let blob = new Blob([ response.data ], { type: response.headers['content-type'] });
			// 	let ref = baseRef;
			// 	ref.current.href = URL.createObjectURL(blob);
			// 	ref.current.download = request.filename;
			// 	ref.current.click();
			// });
		} catch (error) {
			enqueueSnackbar('error downloading file ' + request.filename, ALERT_VARIANT_ERROR);
			//console.log(error);
		}
	};

	return (
		<span style={{ marginRight: '6px' }}>
			<a style={{ display: 'none' }} href="empty" ref={baseRef}>
				ref
			</a>
			<Button
				variant="contained"
				component="span"
				//className={classes.buttonPrimary}
				disabled={!request || !request.url}
				onClick={(event) => {
					downloadFileFromServer(request);
				}}
			>
				{buttonText != '' &&
					<span style={{ paddingRight: '2px' }}>{buttonText}</span>
				}
				<CloudDownloadIcon size='large' />
			</Button>
		</span>
	);
};
