export const inventoryConstants = {
	INVENTORY_SEARCH_REQUEST: 'INVENTORY_SEARCH_REQUEST',
	INVENTORY_SEARCH_SUCCESS: 'INVENTORY_SEARCH_SUCCESS',
	INVENTORY_SEARCH_FAILURE: 'INVENTORY_SEARCH_FAILURE',

	INVENTORY_SEARCH_FILTERS_UPDATE: 'INVENTORY_SEARCH_FILTERS_UPDATE',

	INVENTORY_ITEM_SELECT_REQUEST: 'INVENTORY_ITEM_SELECT_REQUEST',
	INVENTORY_UPDATE_SELECTED_ITEM_REQUEST: 'INVENTORY_UPDATE_SELECTED_ITEM_REQUEST',

	INVENTORY_ITEM_IMAGES_REQUEST: 'INVENTORY_ITEM_SELECT_REQUEST'
};
