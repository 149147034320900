import React from 'react'
import { Grid, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    nametitle: {
        fontWeight: 700
    },
    secondline: {
        color: 'gray',
        fontSize: '12px'
    }
}));

export const UserInfoSummary = (props) => {
    const classes = useStyles();
    const { userprofile } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box className={classes.nametitle}>
                    {userprofile.email}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.secondline}>last login: October 14, 2021 @ 10pm EST</Box>
            </Grid>
        </Grid >
    )
}