import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryConstants } from '../../constants';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { Divider, makeStyles, Fab, Tooltip, Grid } from '@material-ui/core';
import { NewInventoryItemObject } from '../../helpers';

const useStyles = makeStyles((theme) => ({
	inventorytoolbar: {
		color: '#000',
		width: '100%',
		marginTop: '5px',
		marginBottom: '5px',
		backgroundColor: '#f9f9f9',
		borderRadius: '5px',
		paddingLeft: '1%',
		paddingTop: '1%'
	},

	grow: {
		flexGrow: 1,
		margin: '0px',
		textAlign: 'center'
	},
	fab: {
		left: theme.spacing(1),
		color: '#fff',
		backgroundColor: '#0081f2'
	},
	layoutbutton: {
		left: theme.spacing(0.5),
		marginRight: '3px',
		color: '#fff',
		backgroundColor: '#0081f2'
	},

	returnbutton: {
		color: '#fff',
		backgroundColor: '#0081f2'
	},
	refreshbutton: {
		color: '#00873c'
	},
	layoutbuttongroup: {
		marginRight: '20px'
	},
	absolute: {
		position: 'absolute',
		bottom: theme.spacing(1),
		right: theme.spacing(1)
	},
	viewModeButton: {
		//margin: '10px'
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& > *': {
			margin: theme.spacing(1)
		}
	}
}));

export const InventoryHeader = (props) => {
	const [selectedViewMode, setSelectedViewMode] = useState('table');
	const { onRefreshData } = props;
	const inventory = useSelector((state) => state.inventory);
	const classes = useStyles();
	const { inventoryView } = props;
	const dispatch = useDispatch();

	const onReturnButtonClicked = () => {
		dispatch({
			type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
			payload: null
		});
	};

	const onAddItemButtonClicked = () => {
		let newItem = NewInventoryItemObject();
		dispatch({
			type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
			payload: newItem
		});
	};

	const getSearchFilterTextMod = (searchquery) => {
		if (searchquery) {
			return (
				<span>
					for <span style={{ fontWeight: 'bold' }}>{searchquery}</span>
				</span>
			);
		}
		return <Fragment />;
	};

	const onRefereshClicked = () => {
		onRefreshData();
	};

	const getInventoryView = (classes, props) => {
		const { inventoryItemsView, onInventoryItemsViewChange } = props;
		let count = props.data ? props.data.length : 0;
		let searchquery = inventory ? inventory.searchquery : '';

		const handleDataViewMode = (e) => {
			const newMode = selectedViewMode == 'table' ? 'list' : 'table';
			setSelectedViewMode(newMode);
			onInventoryItemsViewChange(newMode);
		};
		return (
			<div className={classes.inventorytoolbar}>
				<Grid container display="flex">
					<Grid container>
						<div>
							Found {count} item(s) {getSearchFilterTextMod(searchquery)}
						</div>
					</Grid>
					<Grid item xs={4}>
						<h3 style={{ textAlign: 'left' }}>Inventory</h3>
					</Grid>

					<Grid container item justifyContent="flex-start" xs={8}>
						<ButtonGroup style={{ marginLeft: '10px', marginRight: '10px' }}>
							<Button
								style={{ marginLeft: '2px', marginRight: '2px', background: 'transparent' }}
								variant="contained"
								onClick={handleDataViewMode}
							>
								<PhotoLibraryIcon style={{ color: '#f76f6f' }} />
							</Button>
							<Button
								style={{ marginLeft: '2px', marginRight: '2px', background: 'transparent' }}
								variant="contained"
								onClick={onRefereshClicked}
							>
								<RefreshIcon style={{ color: '#00873c' }} />
							</Button>
							<Button
								style={{ marginLeft: '2px', marginRight: '2px', background: 'transparent' }}
								variant="contained"
								onClick={onAddItemButtonClicked}
							>
								<AddIcon style={{ color: '#0081f2' }} />
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</div>
		);
	};

	const getDetailView = (classes, props) => {
		return (
			<div style={{ width: '100%', marginTop: '10px' }}>
				<Grid container>
					<Grid item>
						<Tooltip title="Return" aria-label="Return">
							<Fab
								size="small"
								color="primary"
								className={classes.returnbutton}
								onClick={onReturnButtonClicked}
							>
								<ArrowBackIcon />
							</Fab>
						</Tooltip>
					</Grid>
				</Grid>
			</div>
		);
	};

	return (
		<div>
			{inventoryView === 'inventory' ? getInventoryView(classes, props) : getDetailView(classes, props)}

			<Divider variant="fullWidth" />
		</div>
	);
};

InventoryHeader.propTypes = {
	data: PropTypes.array.isRequired,
	selectedSortOption: PropTypes.string,
	inventoryItemsView: PropTypes.string,
	onInventoryItemsViewChange: PropTypes.func.isRequired,
	sortOptionChanged: PropTypes.func.isRequired,
	inventoryView: PropTypes.string.isRequired
};
