import gql from 'graphql-tag';
export const GQL_UPDATE_INVENTORYITEM = gql`
	mutation UpdateInventoryItem($emoid: String!, $input: UpdateInventoryItemInput!) {
		updateInventoryItem(emoid: $emoid, input: $input) {
			rid
		}
	}
`;

export const GQL_CREATE_INVENTORYITEM = gql`
	mutation UpdateInventoryItem($eid: String!, $input: InventoryItemInput!) {
		createInventoryItem(eid: $eid, input: $input) {
			rid
		}
	}
`;
