import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export async function ApiChangePassword(request, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/user/pwc';
    try {
        const response = await axios.post(url, request, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}



export async function ApiGetUserInfo(request, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/user/info';
    try {
        const response = await axios.post(url, request, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}

export async function ApiSaveUserProfile(request, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/user/profile';
    try {
        const response = await axios.put(url, request, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}

export async function ApiGetBusinessInfo(eid, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/biz/info/'+eid;
    try {
        const response = await axios.get(url, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}

export async function ApiSaveBusinessProfile(request, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/biz/profile/' + request.eid;    
    try {
        const response = await axios.put(url, request, headers);        
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}

export async function ApiGetBusinessLogo(eid, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/biz/logob64/'+eid;
    try {
        const response = await axios.get(url, headers);
        const resp = "data:image/gif;base64," + response.data
		onSuccess(resp);
    } catch (error) {
        onFailure(error)
    }
}

export async function ApiUpdateBusinessLogo(request, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    const url = '/biz/profile/' + request.eid;    
    try {
        const response = await axios.put(url, request, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}