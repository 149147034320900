import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	typography: {
		//fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		button: {
			textTransform: 'none'
		}
	}
});

export default theme;
