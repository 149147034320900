import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { authHeader } from '../helpers';
import { makeStyles } from '@material-ui/core';
import NotFound from '../resources/notfound.dat';
const useStyles = makeStyles((theme) => ({
	image: {
		padding: '1px',
		maxWidth: '800px',
		height: '200px',
		borderRadius: '6px'
	},

	fullimage: {
		maxHeight: '1200px',
		width: 'auto',
		objectFit: 'cover'
	}
}));

const Image = (props) => {
	//state = { source: null };
	const { imageItem, clickable } = props;

	const { handleOnClick } = props;
	const classes = useStyles();
	const [imageData, setImageData] = useState();
	async function fetchImageData() {
		const headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/json'
		};
		let response;
		try {
			//if the image is a notfound image, then do a local data load
			if (!imageItem.src) {
				fetch(NotFound).then((r) => r.text()).then((data) => {
					setImageData('data:image/jpeg;base64,' + data);
				});
			} else {
				response = await axios.get(imageItem.src, headers);
				setImageData('data:image/jpeg;base64,' + response.data);
			}
		} catch (error) {
			//console.log(error);
		}
	}
	useEffect(() => {
		fetchImageData();
	});
	return (
		<img
			alt="the image"
			className={clickable ? classes.image : classes.fullimage}
			src={imageData}
			onClick={(event) => {
				if (clickable) {
					handleOnClick(imageItem);
				}
			}}
		/>
	);
};

export default Image;
