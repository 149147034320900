import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import ReactList from 'react-list';
const useStyles = makeStyles((theme) => ({
	taglist: {
		overflow: 'auto',
		maxHeight: '50px',
		overflowX: 'auto',
		overflowY: 'hidden',
		textAlign: 'left'
	},
	tagitem: {
		fontWeight: '550'
	}
}));

export const InventoryTagsBar = (props) => {
	const { tags, tagSearch } = props;
	const classes = useStyles();
	const renderItem = (index, key) => {
		return (
			<Button
				color="primary"
				key={key}
				onClick={() => {
					tagSearch(tags[index]);
				}}
			>
				<p className={classes.tagitem}>{tags[index]}</p>
			</Button>
		);
	};
	return (
		<Box name="taglist" className={classes.taglist}>
			{tags && <ReactList axis="x" itemRenderer={renderItem} length={tags.length} type="uniform" />}
		</Box>
	);
};
