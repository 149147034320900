import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../helpers/history';
import { alertActions, userActions, inventoryActions } from '../actions';
import { LoginPage } from '../LoginPage';
import { ResearchPage } from '../ResearchPage';
import { InventoryPage } from '../InventoryPage';
import { SalesPage } from '../SalesPage';
import { MyAccountPage } from '../MyAccountPage';
import { PrivateRoute, Header } from '../components';
import './App.css';
import { Footer } from './Footer';
import { Profile } from '../components/profile'
class App extends React.Component {
	refHandlers = {
		toaster: (ref) => (this.toaster = ref)
	};
	constructor(props) {
		super(props);
		this.state = {
			selected: 'home',
			expanded: false
		};

		history.listen((location, action) => {
			this.props.clearAlerts();
		});

		this.addToast = this.addToast.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onToggle = this.onToggle.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.performInventorySearch = this.performInventorySearch.bind(this);
	}

	addToast = (alert, icon, intent) => {
		this.toaster.show(alert);
		setTimeout(() => {
			this.props.clearAlerts();
		}, 500);
	};
	onSelect = (selected) => {
		this.setState({ selected: selected });
		switch (selected) {
			case 'home':
				history.push('/');
				break;

			default:
				break;
		}
	};
	onToggle = (expanded) => {
		this.setState({ expanded: expanded });
	};

	handleLogout = () => {
		this.props.logoutUser();
		this.props.createAlert('warn', 'logged out');
	};

	performInventorySearch = ({ filter }) => {
		this.props.searchinventory(filter);
	};

	onCloseProfileDialog = () => {
		//console.log('Profile dialog has been closed');
	}

	render() {
		const { user, main } = this.props;
		const isLoggedIn = localStorage.getItem('user') ? true : false;
		return (
			<div className="app">
				{/* <Toaster position={Position.BOTTOM_RIGHT} ref={this.refHandlers.toaster} maxToasts={1} timeout />
				{alert.message && this.addToast(alert, 'warning-sign', Intent.DANGER)} */}
				{isLoggedIn && (
					<Header
						className="header-container"
						user={user}
						logout={this.props.logoutUser}
						createAlert={this.props.createAlert}
						performInventorySearch={this.performInventorySearch}
					/>
				)}
				<Router history={history}>
					<div className="body-container">
						<PrivateRoute loggedIn={isLoggedIn} exact path="/inventory" component={InventoryPage} />
						{/* <PrivateRoute loggedIn={isLoggedIn} exact path="/portal" component={MainPortalPage} /> */}
						{/* <PrivateRoute loggedIn={isLoggedIn} exact path="/" component={MainPortalPage} /> */}
						{/* NOTE: Redirectory the homepage to inventory page */}

						<PrivateRoute loggedIn={isLoggedIn} exact path="/" component={InventoryPage} />
						<PrivateRoute loggedIn={isLoggedIn} exact path="/sales" component={SalesPage} />
						<PrivateRoute loggedIn={isLoggedIn} exact path="/research" component={ResearchPage} />
						<PrivateRoute loggedIn={isLoggedIn} exact path="/myaccount" component={MyAccountPage} />
						{/* <Route exact path="/" component={HomePage} /> */}
						<Route exact path="/login" component={LoginPage} />
					</div>
				</Router>
				{user &&
					<Profile onCloseProfileDialog={this.onCloseProfileDialog} open={main.showProfileDialog} user={user}></Profile>
				}
				<Footer className="footer-container" />
			</div>
		);
	}
}

function mapState(state) {
	const { alert, authentication, admin, main } = state;
	const { user } = authentication;
	return { alert, user, admin, main };
}

const actionCreators = {
	clearAlerts: alertActions.clear,
	logoutUser: userActions.logout,
	createAlert: alertActions.createAlert,
	searchinventory: inventoryActions.searchinventory
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
