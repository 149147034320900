import React, { useState } from 'react'
import { Tooltip, Fab, Box, Button } from '@mui/material'
import { UserProfileForm } from './UserProfileForm';
import { makeStyles } from '@mui/styles'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useSnackbar } from 'notistack';
import lodash from 'lodash';

const useStyles = makeStyles((theme) => ({
    passwordChange: {
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export const UserProfileView = (props) => {
    const classes = useStyles();
    const { userprofile, saveUserProfileChanges } = props;
    const [showingPasswordResetOptions, setShowingPasswordResetOptions] = useState(false);
    const [editMode, setEditMode] = useState(false)
    //const { enqueueSnackbar } = useSnackbar();
    const [currentuserprofile, setcurrrentuserprofile] = useState({ ...userprofile });

    const handleChangeEvent = (event) => {
        const { name, value } = event.target;
        //console.log(name, value);
        handleChange(name, value);
    };
    const handleChange = (path, value) => {
        const currItem = lodash.cloneDeep(currentuserprofile);
        lodash.set(currItem, path, value);
        setcurrrentuserprofile({ ...currItem });
    };

    const onCancelChanges = (event) => {
        event.stopPropagation();
        setcurrrentuserprofile({ ...userprofile })
        setEditMode(false);
    };

    const onSaveChanges = (event) => {
        event.stopPropagation();
        saveUserProfileChanges(currentuserprofile)
        setEditMode(false)
    }

    const onSetEditMode = (mode) => {
        setEditMode(mode);
    };

    return (
        <Box>
            <div className={classes.passwordChange} >
                <Button name="passwordchange" variant="contained" size="small">
                    Change Password
                </Button>
                <div>
                    <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === true}>
                        <Fab
                            size="small"
                            color="primary"
                            className={classes.fab}
                            onClick={(event) => {
                                event.stopPropagation();
                                onSetEditMode(true)
                            }}
                        >
                            <EditRoundedIcon />
                        </Fab>
                    </Tooltip>

                    <Tooltip title="Cancel" aria-label="Cancel Changes" hidden={!editMode}>
                        <Fab size="small" color="primary" className={classes.fab} onClick={onCancelChanges}>
                            <CancelRoundedIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === false}>
                        <Fab size="small" color="primary" className={classes.fab} onClick={onSaveChanges}>
                            <SaveRoundedIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
            <div>
                {userprofile &&
                    <UserProfileForm editMode={editMode} userprofile={currentuserprofile} handleChangeEvent={handleChangeEvent}></UserProfileForm>
                }
            </div>
        </Box>

    )
}