import { mainConstants } from '../constants';

const initialState = {
	showProfileDialog: false
};
export function main(state = initialState, action) {
	switch (action.type) {
		case mainConstants.MAIN_SET_PROFILE_DISPLAY:		
			return {
				...state,
				showProfileDialog: action.payload				
			};		
		default:
			return state;
	}
}