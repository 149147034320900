//InventoryItemDocumentsView
import React from 'react';
import { makeStyles, Grid, CardMedia } from '@material-ui/core';
import { ImageCarousel } from '../carousel';
import { UploadFile } from '../UploadFile';
const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		flexGrow: 1,
		backgroundColor: '#9e9e9e27',
		textAlign: 'left',
		justifyContent: 'space-between'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	midGridStyle: { minHeight: '100px', backgroundColor: 'transparent', marginBottom: '10px' }
}));

export const InventoryItemImagesView = ({ item, extData }) => {
	const classes = useStyles();
	const isDisabled = (item.deleted === 1 || extData == true) ? true : false;
	const imgUploadUrl = "/image/" + item.rid;
	return (
		<div name="inventoryitemimagesview">
			<Grid container direction="row" className={classes.midGridStyle}>
				<Grid container item xs={12} className={classes.toolbar}>
					<Grid item xs={10}>
						<UploadFile item={item} url={imgUploadUrl} disabled={isDisabled} />
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center">
					<Grid item xs={12}>
						<CardMedia className={classes.media} title={item.title}>
							<ImageCarousel item={item} extData={extData} />
						</CardMedia>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
