import { inventoryConstants } from '../constants';
export const inventoryActions = {
	inventory
};

function inventory(filter) {
	return (dispatch) => {
		dispatch(request(filter));
	};

	function request(filter) {
		return { type: inventoryConstants.INVENTORY_SEARCH_REQUEST, filter };
	}
	// function success(response) {
	// 	return { type: inventoryConstants.INVENTORY_SEARCH_SUCCESS, filter };
	// }
	// function failure(error) {
	// 	return { type: inventoryConstants.INVENTORY_SEARCH_FAILURE, error };
	// }
}
