import React, { useState, useEffect, Fragment } from 'react';
import { Box, Grid, makeStyles, TextField, CardHeader, Tooltip, Fab } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { authHeader } from '../../helpers';
import axios from 'axios';
import { inventoryConstants } from '../../constants';
import lodash from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { getUserEID } from '../../helpers';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_ERROR, ALERT_VARIANT_SUCCESS } from '../../helpers/alert';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex'
	},
	maingrid: {
		width: '100%',
		margin: '0px'
	},
	accordionMediaHeader: {
		display: 'flex'
	},

	detailgrid: {
		textAlign: 'left',
		width: '100%',
		height: '100%'
	},
	metabox: {
		textAlign: 'left',
		width: '100%',
		marginBottom: '10px'
	},
	fab: {
		right: theme.spacing(1),
		margin: theme.spacing(0.5),
		color: '#fff',
		backgroundColor: '#0081f2'
	}
}));

export const InventoryItemDetailsForm = (props) => {
	const classes = useStyles();
	const { item, onResetSelectedItem, extData } = props;
	const [selectedItem, setSelectedItem] = useState({ ...item });
	const dispatch = useDispatch();
	const [editMode, setEditMode] = useState(
		selectedItem.isnew ? (selectedItem.isnew === true ? true : false) : false
	);
	const isReadonly = editMode === false ? true : false;
	const inventory = useSelector((state) => state.inventory);
	const { enqueueSnackbar } = useSnackbar();

	const handleChangeEvent = (event) => {
		const { name, value } = event.target;
		handleChange(name, value);
	};

	const handlePriceChangeEvent = (event, currency) => {
		//console.log('price change is handled differently');
		if (!currency) {
			currency = '$';
		}
		const { name, value } = event.target;
		const price = {
			value: value,
			currency: currency
		};
		handleChange(name, price);
	};

	const handleChange = (path, value) => {
		//console.log('path=', path, ', value=[', value, ']');
		//let currItem = { ...selectedItem };
		const currItem = lodash.cloneDeep(selectedItem);
		lodash.set(currItem, path, value);
		setSelectedItem({ ...currItem });
	};

	const addCurrencyToLabel = (label, price) => {
		if (price) {
			return label + '(' + price.currency + ')';
		}
		return label;
	};

	async function createInventoryItem() {
		if (!selectedItem || selectedItem.title === "") {
			enqueueSnackbar('Please fill all required fields', ALERT_VARIANT_ERROR);
			return
		}

		const eid = getUserEID();
		//console.log('creating inventory item');
		let headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/text'
		};
		//get the notes from localstorage
		let data = {
			eid: eid,
			title: selectedItem.title,
			description: selectedItem.description,
			details: selectedItem.details
		};
		const createItemUrl = '/item';
		try {
			axios.post(createItemUrl, data, headers).then((response) => {
				enqueueSnackbar('successfully created new item', ALERT_VARIANT_SUCCESS);
				selectedItem.isnew = false;

				setEditMode(false);
				onResetSelectedItem(null);
			});
		} catch (error) {
			//console.log(error);
			//TODO: Show error toast
			enqueueSnackbar('Error creating new item', ALERT_VARIANT_ERROR);
		}
	}

	async function updateInventoryItem() {
		//console.log('updating inventory item');
		let headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/text'
		};
		//get the notes from localstorage
		let data = {
			rid: selectedItem.rid,
			title: selectedItem.title,
			description: selectedItem.description,
			details: selectedItem.details,
			deleted: selectedItem.deleted
		};
		const updateItemUrl = '/item/' + selectedItem.rid;
		try {
			// response = await axios.post(notesUpdateUrl, selectedItemNotes, headers);
			// console.log(response.data);
			axios.put(updateItemUrl, data, headers).then((response) => {
				//console.log(response.data);
				//update reducer
				dispatch({
					type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
					payload: selectedItem
				});
				setEditMode(false);
			});
			// console.log(response.data);
		} catch (error) {
			//console.log(error);
			//TODO: Show error toast
		}
	}

	const saveChanges = (event) => {
		event.stopPropagation();
		//TODO: create a notification
		if (selectedItem.isnew === true) {
			createInventoryItem();
		} else {
			updateInventoryItem();
		}
		//refreshData();
	};

	// const refreshData = () => {
	// 	console.log('refreshing data');
	// 	dispatch({
	// 		type: inventoryConstants.INVENTORY_SEARCH_REQUEST,
	// 		payload: { search: '', filters: null }
	// 	});
	// };

	const onCancelChanges = (event) => {
		event.stopPropagation();
		setSelectedItem({ ...item });
		setEditMode(false);
	};

	const onSetEditMode = (event) => {
		console.log('onSetEditMode called')
		//do not allow editing of external data
		if (extData === false) {
			setEditMode(true);
			event.stopPropagation();
		}
	};

	const getAvatar = () => {
		return <h4>Details</h4>;
	};

	useEffect(
		() => {
			//setSelectedItem({ ...selectedItem });
			//console.log('item was changed');
		},
		[item]
	);

	const renderHeader = () => {
		return (
			// <div style={{ display: 'flex', justifyContent: 'space-between' }}>
			// 	<h4 style={{ textAlign: 'left' }}>Details</h4>
			// 	<button>Edit</button>
			// </div>

			<CardHeader
				className={classes.itemheader}
				avatar={getAvatar()}
				action={
					<div>
						<Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === true}>
							<Fab
								disabled={item.deleted === 1 || extData}
								size="small"
								color="primary"
								className={classes.fab}
								onClick={onSetEditMode}
							>
								<EditIcon />
							</Fab>
						</Tooltip>

						<Tooltip title="Cancel" aria-label="Cancel Changes" hidden={!editMode}>
							<Fab size="small" color="primary" className={classes.fab} onClick={onCancelChanges}>
								<CancelIcon />
							</Fab>
						</Tooltip>
						<Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === false}>
							<Fab size="small" color="primary" className={classes.fab} onClick={saveChanges}>
								<SaveIcon />
							</Fab>
						</Tooltip>
					</div>
				}
			/>
		);
	};

	return (
		<Fragment>
			<Box>
				{/* TODO: Why does this render happend on a mouse click in the notes editor? {console.log('render in details header')} */}
				{renderHeader()}
				<Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
					<Box p={1}>
						<TextField
							name="title"
							label="Title"
							multiline
							maxRows={2}
							value={selectedItem.title ? selectedItem.title : ''}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							fullWidth={true}
							variant="outlined"
						/>
					</Box>
					<Box p={1}>
						<TextField
							name="eid"
							label="Business"
							maxRows={1}
							value={selectedItem.eid ? selectedItem.eid : ''}
							InputProps={{
								readOnly: isReadonly
							}}
							readOnly
							fullWidth={true}
							variant="outlined"
						/>
					</Box>

					<Box p={1}>
						<TextField
							name="details.vin"
							label="ID"
							maxRows={1}
							value={selectedItem.details.vin}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							variant="outlined"
						/>
					</Box>
					<Box p={1}>
						<TextField
							name="details.year"
							label="Year"
							maxRows={1}
							value={selectedItem.details.year}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							variant="outlined"
						/>
					</Box>
					<Box p={1}>
						<TextField
							name="details.exteriorcolor"
							label="Color"
							maxRows={1}
							value={selectedItem.details.exteriorcolor}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							variant="outlined"
						/>
					</Box>
					<Box p={1}>
						<TextField
							name="details.trim"
							label="Trim"
							maxRows={1}
							value={selectedItem.details.trim}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							variant="outlined"
						/>
					</Box>

					<Box p={1}>
						<TextField
							name="details.purchaseprice"
							label={addCurrencyToLabel('Purchase Price', selectedItem.details.targetprice)}
							maxRows={1}
							value={selectedItem.details.purchaseprice ? selectedItem.details.purchaseprice.value : ''}
							onChange={(event) => {
								handlePriceChangeEvent(
									event,
									selectedItem.details.purchaseprice.currency
										? selectedItem.details.purchaseprice.currency
										: ''
								);
							}}
							contentEditable={false}
							variant="outlined"
						/>
					</Box>
					<Box p={1}>
						<TextField
							name="details.targetprice"
							label={addCurrencyToLabel('Target Price', selectedItem.details.targetprice)}
							maxRows={1}
							value={selectedItem.details.targetprice ? selectedItem.details.targetprice.value : ''}
							onChange={(event) => {
								handlePriceChangeEvent(
									event,
									selectedItem.details.targetprice.currency
										? selectedItem.details.targetprice.currency
										: ''
								);
							}}
							contentEditable={false}
							variant="outlined"
						/>
					</Box>
					<Box p={1}>
						<TextField
							name="details.purchasedate"
							label="Purchase Date"
							maxRows={1}
							value={selectedItem.details.purchasedate}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							variant="outlined"
						/>
					</Box>

					<Box p={1}>
						<TextField
							name="details.saledate"
							label="Sale Date"
							maxRows={1}
							value={selectedItem.details.saledate ? selectedItem.details.saledate : ''}
							onChange={handleChangeEvent}
							InputProps={{
								readOnly: isReadonly
							}}
							variant="outlined"
						/>
					</Box>
				</Box>
			</Box>
			<Grid item container xs={12}>
				<Grid className={classes.detailgrid}>
					<TextField
						name="description"
						label="Description"
						multiline
						maxRows={2}
						value={selectedItem.description ? selectedItem.description : ' '}
						onChange={handleChangeEvent}
						InputProps={{
							readOnly: isReadonly
						}}
						fullWidth={true}
						variant="outlined"
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};
