import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles, Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
	salestoolbar: {
		color: '#000',
		width: '100%',
		marginTop: '5px',
		marginBottom: '5px',
		backgroundColor: '#f9f9f9',
		borderRadius: '5px',
		paddingLeft: '1%',
		paddingTop: '1%'
	},

	grow: {
		flexGrow: 1,
		margin: '0px',
		textAlign: 'center'
	},
	fab: {
		left: theme.spacing(1),
		color: '#fff',
		backgroundColor: '#0081f2'
	},
	layoutbutton: {
		left: theme.spacing(0.5),
		marginRight: '3px',
		color: '#fff',
		backgroundColor: '#0081f2'
	},

	returnbutton: {
		color: '#fff',
		backgroundColor: '#0081f2'
	},
	refreshbutton: {
		color: '#00873c'
	},
	layoutbuttongroup: {
		marginRight: '20px'
	},
	absolute: {
		position: 'absolute',
		bottom: theme.spacing(1),
		right: theme.spacing(1)
	},
	viewModeButton: {
		//margin: '10px'
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& > *': {
			margin: theme.spacing(1)
		}
	}
}));

export const SalesHeader = (props) => {
	const { onRefreshData } = props;
	const sales = useSelector((state) => state.sales);
	const classes = useStyles();
	const getSearchFilterTextMod = (searchquery) => {
		if (searchquery) {
			return (
				<span>
					for <span style={{ fontWeight: 'bold' }}>{searchquery}</span>
				</span>
			);
		}
		return <Fragment />;
	};

	const onRefereshClicked = () => {
		onRefreshData();
	};


	let searchquery = sales ? sales.searchquery : '';
	let count = props.data ? props.data.length : 0;
	return (
		<div>
			<div className={classes.salestoolbar}>
				<Grid container display="flex">
					<Grid container>
						<div>
							Found {count} item(s) {getSearchFilterTextMod(searchquery)}
						</div>
					</Grid>
					<Grid item xs={4}>
						<h3 style={{ textAlign: 'left' }}>Sales</h3>
					</Grid>

					<Grid container item justifyContent="flex-start" xs={8}>
						<ButtonGroup style={{ marginLeft: '10px', marginRight: '10px' }}>
							<Button
								style={{ marginLeft: '2px', marginRight: '2px', background: 'transparent' }}
								variant="contained"
								onClick={onRefereshClicked}
							>
								<RefreshIcon style={{ color: '#00873c' }} />
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</div>
			<Divider variant="fullWidth" />
		</div>
	);
};

SalesHeader.propTypes = {
	data: PropTypes.array.isRequired,
	selectedSortOption: PropTypes.string,
	sortOptionChanged: PropTypes.func.isRequired,
};
