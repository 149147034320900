export const ALERT_VARIANT_DEFAULT = {
	variant: 'default'
};

export const ALERT_VARIANT_INFO = {
	variant: 'info'
};

export const ALERT_VARIANT_SUCCESS = {
	variant: 'success'
};
export const ALERT_VARIANT_ERROR = {
	variant: 'error'
};

export const ALERT_VARIANT_WARNING = {
	variant: 'warning'
};
