import React, { useState } from 'react';
import { CardHeader, Fab, Tooltip, makeStyles, Avatar } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getDateInFormatOne, updateInventoryItem } from '../../helpers';
import { inventoryConstants } from '../../constants';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../../helpers/alert';
import { AlertDialog } from '../AlertDialog';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	fab: {
		right: theme.spacing(1),
		margin: theme.spacing(0.5),
		color: '#fff',
		backgroundColor: '#0081f2'
	},
	avatar: {
		backgroundColor: '#0081f2'
	},
	avatarexternal: {
		backgroundColor: '#009688'
	},
	avatardeleted: {
		backgroundColor: '#f50057'
	},
	deleteditem: {
		backgroundColor: '#fb020230'
	},
	externalitem: {
		backgroundColor: '#ffeb3b1f'
	},
	itemheader: {
		backgroundColor: '#9e9e9e0a',
		textAlign: 'center',
		display: 'flex'
	},
	deleteditem: {
		borderTop: '3px #fb020230',
		backgroundColor: '#9e9e9e0a',
		textAlign: 'center'
	}
}));
export const InventoryItemHeader = (props) => {
	const classes = useStyles();
	const { item, showDelete, extData } = props;
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const { onClickHeader } = props;
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	//Logic to determine the Avatar symbol
	const getItemAvatarNotation = () => {
		//TODO: add status field to the InventoryItem->details
		//testing
		let status = 'new';
		if (item.deleted === 1) {
			status = 'deleted';
		}
		let avatar = 'E';
		switch (status) {
			case 'new':
				avatar = 'N';
				break;
			case 'sold':
				avatar = 'S';
				break;
			case 'available':
				avatar = 'A';
				break;
			case 'customs':
				avatar = 'C';
				break;
			case 'deleted':
				avatar = 'D';
				break;
			case 'transit':
				avatar = 'T';
				break;
			case 'paperwork':
				avatar = 'P';
				break;
			default:
				avatar = 'E';
				break;
		}

		return avatar;
	};

	const getAvatar = () => {
		return (
			<Avatar aria-label="status" className={extData ? classes.avatarexternal : classes.avatar}>
				{getItemAvatarNotation()}
			</Avatar>
		);
	};

	const onDeleteConfirmation = (result) => {
		if (result === true && showDelete === true) {
			deleteItem();
		}
	};

	const onAlertDialogClose = () => {
		setIsAlertDialogOpen(false);
	};

	const deleteItem = () => {
		updateInventoryItem({ ...item, deleted: 1 }).then((result) => {
			if (result === true) {
				enqueueSnackbar('Successfully deleted ' + item.title, ALERT_VARIANT_SUCCESS);
				dispatch({
					type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
					payload: null
				});
			} else {
				enqueueSnackbar('Failed to delete ' + item.title, ALERT_VARIANT_ERROR);
			}
		});
	};
	return (
		<CardHeader
			onClick={(event) => {
				//console.log('item header clicked');
				onClickHeader(event);
			}}
			//className={classes.itemheader}
			className={clsx(classes.itemheader, {
				[classes.deleteditem]: item.deleted === 1
			}), extData ? classes.externalitem : ''}
			avatar={getAvatar()}
			title={item.title}
			subheader={getDateInFormatOne(item.details.purchasedate)}
			action={
				showDelete && (
					<div>
						<Tooltip title="Delete" aria-label="Delete">
							<Fab
								disabled={item.deleted === 1 || extData}
								size="small"
								color="secondary"
								className={classes.returnbutton}
								onClick={() => {
									setIsAlertDialogOpen(true);
								}}
							>
								<DeleteForeverIcon />
							</Fab>
						</Tooltip>
						<AlertDialog
							open={isAlertDialogOpen}
							title="Delete Confirmation"
							content="Are you sure you want to delete this item?"
							handleConfirm={onDeleteConfirmation}
							handleClose={onAlertDialogClose}
						/>
					</div>
				)
			}
		/>
	);
};

InventoryItemHeader.propTypes = {
	item: PropTypes.object,
	viewMode: PropTypes.string.isRequired,
	editMode: PropTypes.bool.isRequired,
	showDelete: PropTypes.bool,
	onClickHeader: PropTypes.func
};
