import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InventoryItem } from './InventoryItem';
import { Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
	box: {
		width: '100%',
		margin: '0px'
	},
	item: {
		padding: theme.spacing(1),
		textAlign: 'center',
		margin: '6px',
		color: theme.palette.text.secondary,
		background: '#9e9e9e27',
		borderRadius: '5px'
	}
}));
export const InventoryItemList = ({ items }) => {
	const classes = useStyles();
	return (
		<Grid className={classes.box} name="inventoryitemlist" container spacing={1}>
			{items.map((item) => {
				return (
					<Grid item xs={12} md={4} lg={3} key={item.rid} className={classes.item}>
						<InventoryItem item={item} />
					</Grid>
				);
			})}
		</Grid>
	);
};

InventoryItemList.defaultProps = {
	items: []
};
