import { inventoryConstants } from '../constants';
import lodash from 'lodash';
import { defaultSearchFilters } from '../helpers';

const initialState = {
	searchfilters: defaultSearchFilters,
	searchquery: '',
	selectedItem: null,
	selectedItemImages: []
};
export function inventory(state = initialState, action) {
	switch (action.type) {
		case inventoryConstants.INVENTORY_SEARCH_REQUEST:
			//NOTE: if filters is not provided in the payload, then use existing
			return {
				...state,
				searchquery: action.payload.search,
				searchfilters: action.payload.filters ? action.payload.filters : state.searchfilters
			};
		case inventoryConstants.INVENTORY_SEARCH_SUCCESS:
			//if search was successul, then we will also reset the current selection
			return {
				...state,
				searchquery: action.payload,
				selectedItem: null
			};
		case inventoryConstants.INVENTORY_SEARCH_FAILURE:
			return state;
		case inventoryConstants.INVENTORY_SEARCH_FILTERS_UPDATE:
			return {
				...state,
				searchfilters: action.payload
			};
		case inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST:
			return {
				...state,
				selectedItem: action.payload
			};
		case inventoryConstants.INVENTORY_UPDATE_SELECTED_ITEM_REQUEST:
			let currItem = state.selectedItem;
			lodash.set(currItem, action.payload.path, action.payload.value);
			return {
				...state,
				selectedItem: currItem
			};
		default:
			return state;
	}
}
