import React from 'react';
import { InventoryItemDetailsForm } from './InventoryItemDetailsForm';
import { Box, makeStyles } from '@material-ui/core';
import { InventoryItemHeader } from './InventoryItemHeader';
const useStyles = makeStyles((theme) => ({
	item: {
		textAlign: 'center',
		margin: '2px',
		borderRadius: '2px'
	}
}));

export const InventoryItemCreateForm = (props) => {
	const classes = useStyles();
	const { item } = props;
	let editMode = true;
	return (
		<Box className={classes.item}>
			<InventoryItemHeader item={item} viewMode="detail" editMode={editMode} onClickHeader={() => { }} />
			<InventoryItemDetailsForm item={item} {...props} extData={false} />
		</Box>
	);
};
