import { authHeader } from './auth-header';
import axios from 'axios';
export const LocalStorageDefaultTTLMsec = 30 * 1000; //30 msec
const CurrencyFixedDecimalPlaces = 2

export function processFrontendLogout() {
	localStorage.removeItem('user');
}

export function updateUserToken(token) {
	let user = JSON.parse(localStorage.getItem('user'));
	if (user != null) {
		user.token = token;
	} else {
		user = {
			token: token
		};
	}

	localStorage.setItem('user', JSON.stringify(user));
}

export function updateUserInfo(userinfo) {
	let user = JSON.parse(localStorage.getItem('user'));
	if (user != null) {
		user = {
			...user,
			...userinfo
		};
	} else {
		user = {
			...userinfo
		};
	}

	localStorage.setItem('user', JSON.stringify(user));
}

export function updateInventoryItemNotes(rid, value) {
	//console.log('updating localstorage with new notes');
	const key = rid + '_notes';
	localStorage.setItem(key, value);
}

export function getInventoryItemNotes(rid) {
	const key = rid + '_notes';
	let notes = localStorage.getItem(key);
	if (!notes) {
		//let check the server
		try {
			pullItemNotesDataFromServer(rid);
			//the pull would have updated the local storage so try it again
			notes = localStorage.getItem(key);
		} catch (error) {
			return null;
		}
	}

	return notes;
}

export function getDateInFormatOne(strdate) {
	let retval = strdate;
	try {
		const objdate = new Date(strdate);
		const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});
		retval = longEnUSFormatter.format(objdate);
	} catch (error) {
		//console.log(error);
		//TODO: Show error toast
	}

	return retval;
	//https://www.toptal.com/software/definitive-guide-to-datetime-manipulation
}

export function getUserEID() {
	let user = JSON.parse(localStorage.getItem('user'));
	if (user && user.eid) {
		return user.eid;
	}
	return '';
}

export function getUser() {
	const user = JSON.parse(localStorage.getItem('user'));
	return user
}

export function getUsername() {
	const user = JSON.parse(localStorage.getItem('user'));
	if (user && user.username) {
		return user.username;
	}
	return ''
}

export function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}

export function setWithExpiry(key, value, ttl) {
	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export async function pullItemNotesDataFromServer(rid) {
	let headers = {
		headers: {
			...authHeader()
		},
		'Content-Type': 'application/json'
	};

	//notes are available under docs
	const notesUrl = '/doc/' + rid + '/' + rid + '_notes.json';

	let response;
	try {
		response = await axios.get(notesUrl, headers);
		updateInventoryItemNotes(rid, response.data);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export async function updateInventoryItem(item) {
	//console.log('updating inventory item');
	let headers = {
		headers: {
			...authHeader()
		},
		'Content-Type': 'application/text'
	};
	let result = false;
	const updateItemUrl = '/item/' + item.rid;
	try {
		let response = await axios.put(updateItemUrl, item, headers);
		if (response) {
			result = true;
		}
	} catch (error) {
		console.log(error);
	}
	return result;
}

export function toCacheNotes(key, value) {
	sessionStorage.setItem(key, JSON.stringify(value));
}

//fromCacheNotes : returns raw content : gets json content from sessionstorage and parses it to raw content
export function fromCacheNotes(key) {
	return JSON.parse(sessionStorage.getItem(key));
}

export function FixedFloat(value) {		
	if(typeof(value) === 'number') {
		return Number.parseFloat(value).toFixed(CurrencyFixedDecimalPlaces)
	}
	return value
}