import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import './SaleItemTable.css';
import { getDateInFormatOne } from '../../helpers';
import { Fab } from '@mui/material'
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const columns = [
    {
        field: 'paidby',
        numeric: false,
        label: 'PaidBy',
        disablePadding: false,
        align: 'left',
        minWidth: 40,
        getter: (value, actionHandler) => {
            return value.paidby
        }
    },
    {
        field: 'amountpaid', numeric: true, label: 'AmountPaid', disablePadding: true, align: 'left', minWidth: 30,
        getter: (value, field, defaultValue) => {
            return value.amountpaid
        }
    },
    {
        field: 'modifiedtime',
        label: 'Date',
        disablePadding: true,
        sortable: true,
        align: 'center',
        minWidth: 30,
        format: (value) => value.toLocaleString('en-US'),
        numeric: false,
        getter: (value, field, defaultValue) => {
            const v = lodash.get(value, field, defaultValue);
            if (!v) {
                return '-';
            } else {
                return getDateInFormatOne(lodash.get(value, field, defaultValue));
            }
        }
    }
];

function descendingComparator(a, b, orderBy) {
    const av = lodash.get(a, orderBy);
    const bv = lodash.get(b, orderBy);
    if (!av || !bv) return 0;
    return bv.localeCompare(av, undefined, {
        numeric: true,
        sensitivity: 'base'
    });
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        return comparator(a[0], b[0]);
    });
    return stabilizedThis.map((el) => el[0]);
}

function TransactionsEnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow className={classes.tableheaderrow}>
                {columns.map((column) => (
                    <TableCell
                        key={column.field}
                        align={column.align}
                        padding={column.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === column.field ? order : false}
                        style={{ minWidth: column.minWidth }}
                    >
                        <TableSortLabel
                            active={orderBy === column.field}
                            direction={orderBy === column.field ? order : 'asc'}
                            onClick={createSortHandler(column.field)}
                        >
                            {column.label}
                            {orderBy === column.field ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TransactionsEnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 100%'
    },
    fab: {
        left: theme.spacing(1),
        color: '#000',
        background: 'red'
    },
}));

const TransactionsEnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { itemCount, addTransaction } = props;

    return (
        <Toolbar
            className={clsx(classes.root)}
        >
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Transactions ({itemCount})
            </Typography>

            <Tooltip title="Make Payment">
                <Fab size="small" color="primary" style={{ backgroundColor: '#007bff' }} onClick={addTransaction}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </Toolbar>
    );
};

TransactionsEnhancedTableToolbar.propTypes = {
    itemCount: PropTypes.number.isRequired,
    addTransaction: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    deleteditem: {
        backgroundColor: '#fb020230'
    },
    tableheaderrow: {
        backgroundColor: '#f3f3f3'
    }
}));

export const InvoiceTransactionsTable = (props) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('modifiedtime');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);
    const { saleitem, selectTransaction, addTransaction } = props;
    const transactions = saleitem.transactions ? saleitem.transactions : [];
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, id) => {
        event.stopPropagation()
        selectTransaction(id)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, transactions.length - page * rowsPerPage);

    const getColumnValue = (item, column) => {
        let value;
        if (column.getter) {
            switch (column.field) {
                case 'id':
                    value = column.getter(item, selectTransaction);
                    break;

                default:
                    value = column.getter(item, column.field, '');
                    break;
            }
        } else {
            value = lodash.get(item, column.field, '');
        }

        return value;
    };

    const showTransactionDialog = () => {
        //console.log('show the current transaction in a dialog')
    }

    useEffect(
        () => {
            setRowsPerPage(transactions.length);
        },
        [transactions]
    );

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TransactionsEnhancedTableToolbar showTransactionDialog={showTransactionDialog} itemCount={transactions.length} addTransaction={addTransaction} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <TransactionsEnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={transactions.length}
                        />
                        <TableBody>
                            {stableSort(transactions, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {


                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, item.id)}
                                            role="row"
                                            tabIndex={-1}
                                            key={item.id}
                                            className={item.deleted === 1 ? classes.deleteditem : null}
                                        >
                                            {columns.map((column) => {
                                                const value = getColumnValue(item, column);
                                                return (
                                                    <StyledTableCell key={column.field} align={column.align}>
                                                        {column.format && typeof value === 'number' ? (
                                                            column.format(value)
                                                        ) : (
                                                            value
                                                        )}
                                                    </StyledTableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 3 : 8) * emptyRows }}>
                                    <TableCell colSpan={3} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 6, 10]}
                    component="div"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};
