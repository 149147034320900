import { salesConstants } from '../constants';
import lodash from 'lodash';
import { defaultSearchFilters } from '../helpers';

const initialState = {
	searchfilters: defaultSearchFilters,
	searchquery: '',
    invoices: [],	
	selectedInvoice: null
};
export function sales(state = initialState, action) {
	switch (action.type) {
		case salesConstants.SALES_SEARCH_REQUEST:		
			return {
				...state,
				searchquery: action.payload.search,
				searchfilters: action.payload.filters ? action.payload.filters : state.searchfilters
			};
		case salesConstants.SALES_SEARCH_SUCCESS:
			//if search was successul, then we will also reset the current selection
			return {
				...state,
				searchquery: action.payload,
				selectedItem: null
			};
		case salesConstants.SALES_SEARCH_FAILURE:
			return state;

		case salesConstants.SALES_ITEM_SELECT_REQUEST:
			return {
				...state,
				selectedInvoice: action.payload
			};			
		case salesConstants.SALES_UPDATE_SELECTED_INVOICE_REQUEST:
			let curr = state.selectedInvoice;
			lodash.set(curr, action.payload.path, action.payload.value);
			return {
				...state,
				selectedInvoice: curr
			};
		default:
			return state;
	}
}