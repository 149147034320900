import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//Good reference for session token
//https://stackoverflow.com/questions/57132814/react-router-dom-redirect-problem-changes-url-does-not-render-component

export const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				//TODO: add a better check for authentication session
				//It should not just rely on the existing of an authenticated user but also
				//a valid session
				//localStorage.getItem('user') ? (
				loggedIn === true ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)}
		/>
	);
};
