import { authHeader, processFrontendLogout } from '../helpers';
import { API } from '../helpers';
import axios from 'axios';
export const userService = {
	login,
	logout,
	register,
	getuserinfo,
	getById,
	update
};

function login(username, password) {
	const data = JSON.stringify({ username, password });
	return API.post('/user/login', data).then(transformResponse).then((response) => {
		return response;
	});
}

function getuserinfo(username) {
	const data = JSON.stringify({ username });
	let headers = {
		headers: {
			...authHeader()
		},
		'Content-Type': 'application/json'
	};
	return axios.post('/user/info', data, headers).then(transformResponse).then((userinfo) => {
		return userinfo;
	});
}

function logout() {
	const data = {};
	let headers = {
		headers: {
			...authHeader()
		},
		'Content-Type': 'application/json'
	};
	return axios.post('/user/logout', data, headers).then(transformResponse).then((response) => {
		return response;
	});
}

function getById(id) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	};

	return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(user)
	};

	return fetch(`/api/user`, requestOptions).then(handleResponse);
}

function update(user) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(user)
	};

	return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.data().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				processFrontendLogout(response.status);
			}

			const error = (data && data.message) || response.statusText;
			//console.log(error);
			return Promise.reject(error);
		}

		return data;
	});
}

function transformResponse(response) {
	const data = response.data;
	if (response.status !== 200) {
		if (response.status === 401) {
			processFrontendLogout(response.status);
		}

		const error = (data && data.message) || response.statusText;
		//console.log(error);
		//const error = ' error';
		return Promise.reject(error);
	}
	return data;
}
