import React from 'react';
import { Grid, makeStyles, TextField, Box, Typography } from '@material-ui/core';
import { FixedFloat } from '../../helpers'
import { InvoiceTransactions } from './InvoiceTransactions'
import Divider from '@mui/material/Divider';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    maingrid: {
        width: '100%',
        margin: '0px'
    },
    accordionMediaHeader: {
        display: 'flex'
    },
    transactions: {
        textAlign: 'left',
        width: '100%',
        height: '100%'
    },
    customerinfo: {
        textAlign: 'left',
        width: '100%',
        height: '100%'
    },

    metabox: {
        textAlign: 'left',
        width: '100%',
        marginBottom: '10px'
    },
    fab: {
        right: theme.spacing(1),
        margin: theme.spacing(0.5),
        color: '#fff',
        backgroundColor: '#0081f2'
    },
    invoicepaid: {
        color: 'green',
        fontWeight: '600'
    },

    invoicepartial: {
        color: 'red',
        fontWeight: '600'
    }

}));

export const






    InvoiceForm = (props) => {
        const classes = useStyles();
        const { item, editMode, onUpdate } = props;
        const { handleChangeEvent, handleFloatChangeEvent } = props;
        const isReadonly = editMode === false ? true : false;
        //console.log('In InvoiceForm, the saleitem is ', item)


        const renderInvoiceStatus = () => {
            let status = item.status ? item.status : '';
            console.log('item status is', status)
            return (
                <span style={status === "Partial" ? { color: 'red' } : { color: '#abc3a4' }}>status</span>
            )
        }

        //     <Box>
        //     <Grid container spacing={1}>
        //         <Grid item xs={10} md={10}><h3>Invoice</h3></Grid>
        //         <Grid item xs={10} md={5}>Balance:{renderBalance(item)}</Grid>
        //         <Grid item xs={10} md={10}><DownloadFile label="Download Invoice" request={prepareInvoiceDownloadRequest(selectedItem.id)} /></Grid>
        //     </Grid>
        // </Box>

        const getBalance = () => {
            if (!item) return;
            let balance = item.totalcost - item.totalpaid
            const sanitized = Number.parseFloat(balance).toFixed(2)
            return sanitized
        }
        return (
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                        Balance
                    </Typography>
                    <Box p={1}>
                        <TextField
                            className={item.status === 'Paid' ? classes.invoicepaid : classes.invoicepartial}
                            label="Outstanding Balance"
                            maxRows={1}
                            value={getBalance()}
                            InputProps={{
                                readOnly: true,
                                className: (item.status === 'Paid' ? classes.invoicepaid : classes.invoicepartial)
                            }}
                            readOnly
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                        Customer Information
                    </Typography>
                    <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                        <Box p={1}>
                            <TextField
                                name="customerinfo.name"
                                label="Name"
                                value={item.customerinfo.name ? item.customerinfo.name : ''}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="customerinfo.company"
                                label="Business"
                                value={item.customerinfo.company ? item.customerinfo.company : ''}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="customerinfo.email"
                                label="Email"
                                maxRows={1}
                                value={item.customerinfo.email ? item.customerinfo.email : ''}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="customerinfo.phone"
                                label="Phone"
                                maxRows={1}
                                value={item.customerinfo.phone ? item.customerinfo.phone : ''}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="customerinfo.address"
                                label="Address"
                                maxRows={1}
                                value={item.customerinfo.address ? item.customerinfo.address : ''}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Divider variant="middle" />
                <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                        Item Information
                    </Typography>

                    <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                        <Box p={1}>
                            <TextField
                                name="itemtitle"
                                label="Item"
                                multiline
                                maxRows={2}
                                value={item.itemtitle ? item.itemtitle : ''}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="eid"
                                label="Business"
                                maxRows={1}
                                value={item.eid ? item.eid : ''}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                readOnly
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                className={item.status === 'Paid' ? classes.invoicepaid : classes.invoicepartial}
                                name="status"
                                label="Status"
                                maxRows={1}
                                value={item.status}
                                InputProps={{
                                    readOnly: isReadonly,
                                    className: (item.status === 'Paid' ? classes.invoicepaid : classes.invoicepartial)
                                }}
                                readOnly
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Box>

                        {/* Handle currency, add currency selector */}
                        <Box p={1}>
                            <TextField
                                name="quotedprice"
                                // TODO: Introduce the selected of currency for prices
                                // label={addCurrencyToLabel('Quoted Price', item.quotedprice)}
                                label="QuotedPrice"
                                type="number"
                                maxRows={1}
                                value={item.quotedprice ? FixedFloat(item.quotedprice) : ''}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                onChange={handleChangeEvent}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="taxpercent"
                                label="Tax%"
                                type="number"
                                maxRows={1}
                                value={item.taxpercent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                onChange={handleChangeEvent}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="fees"
                                label="Fees"
                                type="number"
                                maxRows={1}
                                value={FixedFloat(item.fees)}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                variant="outlined"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                name="totalcost"
                                label="TotalCost"
                                type="number"
                                maxRows={1}
                                value={FixedFloat(item.totalcost)}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                variant="outlined"
                            />
                        </Box>
                        <Grid className={classes.customerinfo}>
                            <TextField
                                name="salenotes"
                                label="Notes"
                                multiline
                                maxRows={2}
                                value={item.salenotes ? item.salenotes : ' '}
                                onChange={handleChangeEvent}
                                InputProps={{
                                    readOnly: isReadonly
                                }}
                                fullWidth={true}
                                variant="outlined"
                            />
                        </Grid>
                    </Box>
                </Grid>
                <Divider variant="middle" />
                <Grid className={classes.transactions} item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                        Invoice Transactions
                    </Typography>
                    {!item.isnew &&
                        <InvoiceTransactions saleitem={item} onUpdate={onUpdate}></InvoiceTransactions>
                    }
                </Grid>
            </Grid>
        );
    };
