import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import history from '../helpers/history';
import { processFrontendLogout, updateUserToken, updateUserInfo } from '../helpers/util';

export const userActions = {
	login,
	logout,
	register,
	getuserinfo
};

function login(username, password) {
	return (dispatch) => {
		dispatch(request({ username }));
		let loginsuccessful = false;
		userService
			.login(username, password)
			.then(
				(response) => {
					updateUserToken(response.token);
					dispatch(success(response));
					//history.push('/');
					loginsuccessful = true;
				},
				(error) => {
					console.error('there was an error when logging in');
					dispatch(failure(error));
					//dispatch(alertActions.error(error));
					//dispatch(alertActions.clear());
				}
			)
			.then(() => {
				//NOTE: Since login was successful, we will request profile
				if (loginsuccessful) {
					//NOTE: Since login was successful, we will request profile
					dispatch(userinfo_request({ username }));
					userService.getuserinfo(username).then(
						(userinfo) => {
							updateUserInfo(userinfo);
							dispatch(userinfo_success(userinfo));
							history.push('/');
						},
						(error) => {
							console.error('there was an error when logging in');
							dispatch(userinfo_failure(error));
						}
					);
				} else {
					processFrontendLogout(401);
				}
			});
	};

	function request(response) {
		return { type: userConstants.LOGIN_REQUEST, response };
	}
	function success(response) {
		return { type: userConstants.LOGIN_SUCCESS, response };
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error };
	}

	function userinfo_request(userinfo) {
		return { type: userConstants.USERINFO_REQUEST, userinfo };
	}
	function userinfo_success(userinfo) {
		return { type: userConstants.USERINFO_SUCCESS, userinfo };
	}
	function userinfo_failure(error) {
		return { type: userConstants.USERINFO_FAILURE, error };
	}
}

function getuserinfo(username) {
	return (dispatch) => {
		dispatch(request({ username }));
		userService.getuserinfo(username).then(
			(userinfo) => {
				updateUserInfo(userinfo);
				dispatch(success(userinfo));
				history.push('/');
				//NOTE: Since login was successful, we will request profile
			},
			(error) => {
				console.error('there was an error when logging in');
				dispatch(failure(error));
				//dispatch(alertActions.error(error));
				//dispatch(alertActions.clear());
			}
		);
	};

	function request(userinfo) {
		return { type: userConstants.USERINFO_REQUEST, userinfo };
	}
	function success(userinfo) {
		return { type: userConstants.USERINFO_SUCCESS, userinfo };
	}
	function failure(error) {
		return { type: userConstants.USERINFO_FAILURE, error };
	}
}

function logout() {
	return (dispatch) => {
		dispatch(request());
		userService.logout().then(
			() => {
				dispatch(success());
				//localStorage.removeItem('user');
				processFrontendLogout();
				//regardless, of success, we will get to root path
				history.push('/');
			},
			(error) => {
				dispatch(failure(error));
				//dispatch(alertActions.error(error));
				//dispatch(alertActions.clear());
			}
		);
	};

	function request() {
		return { type: userConstants.LOGOUT_REQUEST };
	}
	function success() {
		return { type: userConstants.LOGOUT_SUCCESS };
	}
	function failure(error) {
		return { type: userConstants.LOGOUT_FAILURE, error };
	}
}

function register(user) {
	return (dispatch) => {
		dispatch(request(user));

		userService.register(user).then(
			(user) => {
				dispatch(success());
				history.push('/');
				dispatch(alertActions.success('Registration successful'));
				dispatch(alertActions.clear());
			},
			(error) => {
				dispatch(failure(error));
				dispatch(alertActions.error(error));
				dispatch(alertActions.clear());
			}
		);
	};

	function request(user) {
		return { type: userConstants.REGISTER_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.REGISTER_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.REGISTER_FAILURE, error };
	}
}
