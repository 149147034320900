import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import {
	makeStyles,
	useTheme,
	Grid,
	IconButton,
	Divider,
	Card,
	CardHeader,
	CardContent
} from '@material-ui/core';
import {
	NavigateBeforeRounded,
	NavigateNextRounded,
	SkipPreviousRounded,
	SkipNextRounded,
	ZoomInRounded,
	ZoomOutRounded,
} from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
	pagenav: {
		backgroundColor: '#9e9e9e12'
	},
	pagenavitem: {
		//padding: theme.spacing(0.1)
	},
	divider: {
		minHeight: '20px'
	},
	documentpage: {
		overflow: 'scroll',
		whiteSpace: 'nowrap'
	},
	cardheader: {
		backgroundColor: '#9e9e9e08',
		textAlign: 'center'
	},
	viewercontainer: {
		width: '90vw',
		height: 'auto',
		overflowX: 'auto',
		overflowY: 'auto'
	}
}));

//request : is the a request object that has a "url" and "httpHeaders"
export const PdfDocumentViewer = ({ shownav, title, request }) => {
	const classes = useStyles();
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const theme = useTheme();
	// const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
	// const matchesMedium = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('lg'));
	// const matchesLarge = useMediaQuery(theme.breakpoints.up('lg'));
	const initialZoom = 1.0;
	const [zoomScale, setZoomScale] = useState(initialZoom);

	//TODO: Figure out how to set initial zoom based on the size of the screen
	// useEffect(() => {
	// 	let responsiveZoom = 1.0;
	// 	if (matchesMedium) {
	// 		responsiveZoom = 1.0;
	// 	} else if (matchesMedium) {
	// 		responsiveZoom = 2;
	// 	} else if (matchesMedium) {
	// 		responsiveZoom = 3.0;
	// 	}
	// 	console.log('changing responsiveZoom ', responsiveZoom);
	// 	setZoomScale(responsiveZoom);
	// });

	const zoomStep = 0.25;
	const maxZoom = 5.0;
	const minZoom = 0.25;
	// console.log('initialZoom ', initialZoom);
	// console.log(' zoomScale ', zoomScale);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const renderPageNav = () => {
		return (
			<Grid container className={classes.pagenav} direction="row" justifyContent="center" alignItems="center">
				<Grid item className={classes.pagenavitem}>
					<IconButton
						aria-label="Restore Size"
						onClick={() => {
							if (zoomScale <= maxZoom) {
								let newScale = zoomScale + zoomStep;
								//protect boundary condition
								if (newScale >= maxZoom) {
									newScale = maxZoom;
								}
								setZoomScale(newScale);
							}
						}}
					>
						<ZoomInRounded color="primary" />
					</IconButton>
				</Grid>
				<Grid item className={classes.pagenavitem}>
					<IconButton
						onClick={() => {
							if (zoomScale > minZoom) {
								let newScale = zoomScale - zoomStep;
								//protect boundary condition
								if (newScale <= minZoom) {
									newScale = minZoom;
								}
								setZoomScale(newScale);
							}
						}}
					>
						<ZoomOutRounded color="primary" />
					</IconButton>
				</Grid>
				<Grid item>
					<Divider className={classes.divider} orientation="vertical" color="black" />
				</Grid>
				<Grid item className={classes.pagenavitem}>
					<IconButton
						disabled={numPages <= 0 || pageNumber === 1 ? true : false}
						onClick={() => {
							if (numPages > 0) {
								setPageNumber(1);
							}
						}}
					>
						<SkipPreviousRounded color="primary" />
					</IconButton>
				</Grid>
				<Grid item className={classes.pagenavitem}>
					<IconButton
						disabled={numPages <= 0 || pageNumber === 1 ? true : false}
						onClick={() => {
							if (pageNumber > 1) {
								setPageNumber(pageNumber - 1);
							}
						}}
					>
						<NavigateBeforeRounded color="primary" />
					</IconButton>
				</Grid>
				<Grid item className={classes.pagenavitem}>
					<IconButton
						disabled={numPages <= 0 || pageNumber >= numPages ? true : false}
						onClick={() => {
							if (pageNumber < numPages) {
								setPageNumber(pageNumber + 1);
							}
						}}
					>
						<NavigateNextRounded color="primary" />
					</IconButton>
				</Grid>
				<Grid item className={classes.pagenavitem}>
					<IconButton
						disabled={numPages <= 0 || pageNumber >= numPages ? true : false}
						onClick={() => {
							if (numPages > 0) {
								setPageNumber(numPages);
							}
						}}
					>
						<SkipNextRounded color="primary" />
					</IconButton>
				</Grid>
				<Grid item className={classes.pagenavitem}>
					<span>
						Page {pageNumber} of {numPages}
					</span>
				</Grid>
			</Grid>
		);
	};
	const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div>
			{renderPageNav()}
			<Card>
				<CardHeader className={classes.cardheader} title={<div>{title}</div>} />
				<CardContent>
					<Grid
						name="documentviewercontainer"
						style={{ width: mobileScreen ? '75vw' : '90vw', height: '60vh', overflow: 'auto' }}
					>
						<Document
							//file="http://localhost:8098/doc/eyJJRCI6ImFiYzExMSIsIkVJRCI6ImJhem1vdG9ycyJ9/BFB_Buggy_parts_required.pdf"
							file={request}
							onLoadSuccess={onDocumentLoadSuccess}
							onLoadError={(error) => {
								//console.log(error);
							}}
						>
							<Page pageNumber={pageNumber} scale={zoomScale} />
						</Document>
					</Grid>
				</CardContent>
			</Card>
		</div>
	);
};

//REFERENCE
//pdf.js releases
//https://github.com/mozilla/pdf.js/tags
