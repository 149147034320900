import React from 'react'
import { Grid, Box, Typography, TextField, Divider } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import { UploadFile } from '../UploadFile';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    detailgrid: {
        textAlign: 'left',
        width: '100%',
        height: '100%'
    },
    metabox: {
        textAlign: 'left',
        width: '100%',
        marginBottom: '10px'
    },
    logoImage: {
        padding: '1px',
        borderRadius: '6px',
        maxWidth: '200px',
        maxHeight: '150px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
            maxHeight: '250px'
        }
    }
}));

export const BusinessProfileForm = (props) => {
    const classes = useStyles();
    const { businessprofile, logoImage, editMode, handleChangeEvent } = props
    const isReadonly = editMode == false ? true : false;
    const logoUploadUrl = "/biz/logo/" + businessprofile.eid;

    // console.log('isReadonly=', isReadonly)
    // console.log('In BusinessProfileForm, businessprofile=', businessprofile)
    return (
        <Grid container xs={12}>
            <Grid item xs={12} md={5} lg={3}>
                <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                    {logoImage &&
                        <img src={logoImage} alt="the image" className={classes.logoImage} />
                    }
                    <div style={{ margin: '4px' }}>
                        <UploadFile uploadButtonText={"Update Logo"} url={logoUploadUrl} multiple={false} />
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>

                <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                    Business Information
                </Typography>
                <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                    <Box p={1}>
                        <TextField
                            name="title"
                            label="Title"
                            maxRows={1}
                            value={businessprofile.title}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="email"
                            label="Email"
                            maxRows={1}
                            value={businessprofile.email}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="phone"
                            label="Phone"
                            maxRows={1}
                            value={businessprofile.phone}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                </Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                    Address Information
                </Typography>
                <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                    <Box p={1}>
                        <TextField
                            name="address.street"
                            label="Street"
                            maxRows={1}
                            value={businessprofile.address.street}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="address.city"
                            label="City"
                            maxRows={1}
                            value={businessprofile.address.city}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="address.zip"
                            label="Zip"
                            maxRows={1}
                            value={businessprofile.address.zip}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="address.stateprovince"
                            label="State/Province"
                            maxRows={1}
                            value={businessprofile.address.stateprovince}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="address.country"
                            label="Country"
                            maxRows={1}
                            value={businessprofile.address.country}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                </Box>
            </Grid>
            <Divider variant="middle" />
        </Grid>
    )
}