import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: false, user: user, loginErrors: false } : {};

export function authentication(state = initialState, action) {
	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return {
				loggingIn: true,
				user: state
			};
		case userConstants.LOGIN_SUCCESS:
			//update the token
			return {
				loggedIn: true,
				user: {
					...user,
					token: action.token
				}
			};
		case userConstants.LOGIN_FAILURE:
			return {
				...state,
				loggingIn: false,
				loggedIn: false,
				loginErrors: true
			};
		case userConstants.LOGOUT_REQUEST:
			return state;
		case userConstants.LOGOUT_SUCCESS:
			return {
				loggedIn: false
			};
		case userConstants.LOGOUT_FAILURE:
			return state;

		case userConstants.USERINFO_REQUEST:
			return state;
		case userConstants.USERINFO_SUCCESS:
			//update the token
			const newstate = {
				...state,
				loggedIn: true,
				user: {
					token: state.token,
					...action.userinfo
				}
			};
			return newstate;
		case userConstants.USERINFO_FAILURE:
			return {};

		default:
			return state;
	}
}
