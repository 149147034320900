import React, { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Card } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { defaultSearchFilters } from '../helpers';
const useStyles = makeStyles((theme) => ({
	searchcontainer: {
		display: 'flex',
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.65)
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '48ch'
		},
		border: '1px solid #d4d4d4'
	},
	searchIcon: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '45ch'
		}
	},
	poppercontent: {
		background: '#f9f9f9',
		padding: theme.spacing(2),
		marginTop: theme.spacing(0.5)
	}
}));

export const SearchBar = ({ onSubmitCallback, onSearchQueryChangeCallback }) => {
	const classes = useStyles();
	const popperButtonRef = useRef();
	const [isPopperOpen, setIsPopperOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchFilters, setSearchFilters] = useState(defaultSearchFilters);

	const handlePopperClick = () => (event) => {
		if (!anchorEl) {
			setAnchorEl(popperButtonRef.current);
		}

		setIsPopperOpen((prev) => {
			return !prev;
		});
	};

	const handleFilterChange = (event) => {
		setSearchFilters({ ...searchFilters, [event.target.name]: event.target.checked });
	};

	const handleClickAway = () => {
		setIsPopperOpen(false);
	};

	return (
		<div>
			<Popper open={isPopperOpen} anchorEl={anchorEl} placement="bottom-start" transition>
				<ClickAwayListener onClickAway={handleClickAway}>
					<Card className={classes.poppercontent}>
						<FormGroup row>
							<FormControlLabel
								control={
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
										checkedIcon={<CheckBoxIcon fontSize="small" />}
										checked={searchFilters.general}
										onChange={handleFilterChange}
										name="general"
									/>
								}
								label="General"
							/>
							<FormControlLabel
								control={
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
										checkedIcon={<CheckBoxIcon fontSize="small" />}
										checked={searchFilters.includedeleted}
										onChange={handleFilterChange}
										name="includedeleted"
									/>
								}
								label="Deleted"
							/>
						</FormGroup>
					</Card>
				</ClickAwayListener>
			</Popper>

			<div className={classes.searchcontainer} name="searchcontainer">
				<IconButton size="small" onClick={handlePopperClick()} ref={popperButtonRef}>
					<ExpandMoreIcon />
				</IconButton>
				<InputBase
					placeholder="Search Inventory…"
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput
					}}
					onChange={onSearchQueryChangeCallback}
					onSubmit={(event) => {
						onSubmitCallback(searchFilters);
					}}
					name="searchinput"
					inputProps={{ 'aria-label': 'search' }}
					style={{ width: '100%' }}
				/>
				<div className={classes.searchIcon} name="searchbuttoncontainer">
					<Button
						onClick={() => {
							onSubmitCallback(searchFilters);
						}}
						style={{ background: '#0081f2', borderRadius: '1px' }}
					>
						<SearchIcon style={{ color: '#fff' }} />
					</Button>
				</div>
			</div>
		</div>
	);
};
