import axios from 'axios';
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { authHeader } from '../helpers';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../helpers/alert';
import { maxFileUploadBytes, maxFileUploadMB } from '../helpers';

export const UploadFile = (props) => {
	const { item, url, onChange, multiple, uploadButtonText, ...other } = props;
	const [baseRef, setBaseRef] = React.useState(React.createRef());
	const { enqueueSnackbar } = useSnackbar();
	const allowMultipleFiles = multiple ? multiple : false
	const uploadFilesToServer = () => {
		let ref = baseRef;
		let files = ref.current.files;
		if (!files) {
			console.log('nofiles selected');
			return;
		}
		let maxFileSizeThresholdReached = false;
		//console.log(files);
		try {
			const formData = new FormData();
			for (const file of files) {
				if (file.size >= maxFileUploadBytes) {
					maxFileSizeThresholdReached = true;
					break;
				}
				formData.append('file', file);
			}

			if (maxFileSizeThresholdReached) {
				enqueueSnackbar(
					'error: file size is larger than allowed limit (' + maxFileUploadMB + ' MB)',
					ALERT_VARIANT_ERROR
				);
			} else {
				const headers = {
					headers: {
						...authHeader()
					},
					'Content-Type': 'multipart/form-data'
				};

				axios
					.post(url, formData, headers)
					.then((response) => {
						enqueueSnackbar('uploaded ' + files.length + ' files', ALERT_VARIANT_SUCCESS);
						if (onChange) {
							onChange();
						}
					})
					.catch((error) => {
						enqueueSnackbar('error uploading files', ALERT_VARIANT_ERROR);
					});
			}
		} catch (error) {
			enqueueSnackbar('error uploading files', ALERT_VARIANT_ERROR);
			//console.log(error);
		}
	};

	const getUploadButton = () => {
		let variant = "text"
		let content = <AddIcon />
		if (uploadButtonText) {
			variant = "contained"
			content = (
				<span>{uploadButtonText}</span>
			)
		}

		return (
			<Button
				{...other}
				color="primary"
				aria-label="upload files"
				onClick={(event) => {
					let ref = baseRef;
					ref.current.click();
				}}
				variant={variant}
			>
				{content}
			</Button>
		)

	}
	return (
		<span>
			<input
				type="file"
				id="file"
				name="file"
				color="primary"
				accept="application/pdf, image/png, image/jpeg"
				style={{ display: 'none' }}
				ref={baseRef}
				multiple={allowMultipleFiles}
				onChange={uploadFilesToServer}
			/>
			{getUploadButton()}
		</span>
	);
};

//REFERENCES:
//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
//https://stackoverflow.com/questions/58381990/react-axios-multiple-files-upload
