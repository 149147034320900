import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { emotoronVersion } from '../helpers/config';
function Copyright() {
	let d = new Date();
	//let options = { month: 'long' };
	//let longmonth = new Intl.DateTimeFormat('en-US', options).format(d);
	return (
		<Tooltip title={emotoronVersion} aria-label={emotoronVersion}>
			<Typography variant="body2" color="textSecondary">
				{'Copyright © '}
				<Link color="inherit" href="https://www.emotoron.com/">
					eMotoron
				</Link>{' '}
				{d.getFullYear()}
				{'.'}
				{/* (v{emotoronVersion} - {longmonth} {d.getDate()}, {d.getFullYear()}) */}
			</Typography>
		</Tooltip>
	);
}

const useStyles = makeStyles((theme) => ({
	footer: {
		postion: 'absolute',
		bottom: '0',
		height: '5.5rem',
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing(3, 2),
		//marginTop: 'auto',
		backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[300]
	}
}));

export const Footer = () => {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<div>
				<Typography variant="body1" />
				<Copyright />
			</div>
		</footer>
	);
};
