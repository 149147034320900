import React, { useState } from 'react';
import { authHeader } from '../../helpers';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import NotFound from '../../resources/notfound.dat';

import {
	Box,
	IconButton,
	CardMedia,
	makeStyles,
	Typography,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { inventoryConstants } from '../../constants';
import { InventoryItemHeader } from './InventoryItemHeader';
import { InventoryTagsBar } from './InventoryTagsBar';
import { InventoryItemTagsSetup } from './InventoryItemTagsSetup';
import { getUserEID } from '../../helpers';
const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex'
	},
	maingrid: {
		width: '100%',
		margin: '0px'
	},
	accordionMediaHeader: {
		display: 'flex'
	},
	item: {
		textAlign: 'center',
		margin: '2px',
		borderRadius: '2px'
	},
	media: {
		height: '50%',
		width: 'auto',
		paddingBottom: '10px',
		paddingTop: '10px',
		border: '1px solid red'
	},
	mainimage: {
		height: '180px',
		width: '100%',
		padding: '1px',
		borderRadius: '6px'

		//objectFit: 'contain'
	},
	line: {
		display: 'flex'
	},
	lineitem: {
		margin: '2px'
	},
	firstline: {
		fontWeight: '900',
		fontSize: 'large'
	},
	taglist: {
		display: 'flex',
		flexDirection: 'row',
		padding: 0
		//justifyContent: 'center'
	},
	scroller: {
		margin: '0 auto',
		height: '300px',
		width: '300px',
		overflow: 'auto'
	},
	tagitem: {
		marginLeft: '5px'
	}
}));

export const InventoryItemSimplifiedView = (props) => {
	const { item, tagSearch } = props;
	const [selectedItem, setSelectedItem] = useState(item);
	const [topImage, setTopImage] = useState();
	const [tagSetupOpen, setTagSetupOpen] = React.useState(false);
	const classes = useStyles();
	const extData = getUserEID() != item.eid;
	const dispatch = useDispatch();

	const addCurrencyToLabel = (label, price) => {
		if (price) {
			return label + ' ' + price.currency + ' ';
		}
		return label;
	};

	const onSetSelectedItem = (event) => {
		//TODO: skip if the current item is already selected
		dispatch({
			type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
			payload: item
		});
		event.stopPropagation();
	};

	const getTopImage = () => {
		//if we are dealing with a new item creation
		if (!item.rid) {
			return;
		}

		let headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/json'
		};

		let src = '';
		if (item.images) {
			const imageItems = Object.values(item.images);
			for (const imageItem of imageItems) {
				src = imageItem.src;
				break;
			}
		}

		let imageUrl = '/image/';
		if (!src) {
			//imageUrl = imageUrl + 'eyJJRCI6ImRlZmF1bHRzIiwiRUlEIjoiZW1vdG9yb24ifQ/notfound.png';
			fetch(NotFound).then((r) => r.text()).then((data) => {
				setTopImage('data:image/jpeg;base64,' + data);
			});
			return;
		} else {
			imageUrl = imageUrl + item.rid + '/' + src;
		}
		//console.log('top image url: ', imageUrl);
		axios.get(imageUrl, headers).then((response) => {
			setTopImage('data:image/jpeg;base64,' + response.data);
		});
	};

	getTopImage();
	const onCloseTagSetup = () => {
		//console.log('closing tag setup window');
		setTagSetupOpen(false);
	};

	const onOpenTagSetup = () => {
		if (!tagSetupOpen && !extData) {
			setTagSetupOpen(!tagSetupOpen);
		}
	}

	const updateTags = (newTags) => {
		if (!newTags) return;
		//console.log('updating tags to new set');
		item.tags = [...newTags];
		saveTags();
	};

	async function saveTags() {
		//console.log('save the tags');
		const tagsUpdateUrl = '/tags/' + item.rid;
		let headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/json'
		};
		try {
			axios.post(tagsUpdateUrl, item.tags, headers).then((response) => {
				//console.log(response.data);
			});
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Box name="conciseview" style={{ overflow: 'auto', width: '100%' }}>
			<InventoryItemHeader
				item={selectedItem}
				viewMode={'dense'}
				editMode={false}
				extData={extData}
				onClickHeader={onSetSelectedItem}
			/>
			<div>
				<CardMedia title={item.title} name="cardmedia" onClick={onSetSelectedItem}>
					<img src={topImage} alt="the image" className={classes.mainimage} />
				</CardMedia>
			</div>
			<Box>
				<Box className={classes.line}>
					<Typography className={`${classes.lineitem} ${classes.firstline}`}>{item.details.year}</Typography>
					<Typography className={`${classes.lineitem} ${classes.firstline}`}>{item.title}</Typography>
					<Typography className={`${classes.lineitem} ${classes.firstline}`}>
						{item.details.trim ? item.details.trim : ' '}
					</Typography>
					<Typography className={`${classes.lineitem} ${classes.firstline}`}>
						({item.details.exteriorcolor ? item.details.exteriorcolor : ' '})
					</Typography>
				</Box>
				<Box className={classes.line}>
					<Typography className={classes.lineitem}>
						{addCurrencyToLabel('', item.details.targetprice)}
						{item.details.purchaseprice ? item.details.purchaseprice.value : ' '}
					</Typography>
					<Typography className={classes.lineitem}>
						{item.details.purchasedate ? item.details.purchasedate : ' '}
					</Typography>
				</Box>
				<Box className={classes.line}>
					<Typography className={classes.lineitem}>
						{item.details.vin ? `${'Vin:'} ${item.details.vin}` : ''}
					</Typography>
				</Box>
				<div style={{ textAlign: 'left' }}>
					Tags:{' '}
					<IconButton
						style={{ marginLeft: '2px', marginRight: '2px' }}
						variant="contained"
						onClick={onOpenTagSetup}
					>
						<SettingsIcon style={{ color: '#0081f2' }} />
					</IconButton>
				</div>
				<InventoryItemTagsSetup
					open={tagSetupOpen}
					onClose={onCloseTagSetup}
					onSaveTags={updateTags}
					item={item}
				/>
				<InventoryTagsBar tags={item.tags} tagSearch={tagSearch} />
				<Box />
			</Box>
		</Box>
	);
};
