import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App/App';
import { Provider } from 'react-redux';
import { store } from './helpers';
//import reportWebVitals from './reportWebVitals';
import Collapse from '@material-ui/core/Collapse';
import { SnackbarProvider } from 'notistack';
//import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import { gqlclient } from './services/graphqlclient';
import { ApolloProvider } from '@apollo/react-hooks';

import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				autoHideDuration={3000}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				TransitionComponent={Collapse}
			>
				<ApolloProvider client={gqlclient}>
					<App />
				</ApolloProvider>
			</SnackbarProvider>
		</MuiThemeProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//TODO: Disabled 11/18/2021
//reportWebVitals();

//REFERENCES:
