import { alertConstants } from '../constants';

export const alertActions = {
	success,
	error,
	clear,
	createAlert
};

function success(message) {
	let alert = {
		message: message,
		icon: 'tick',
		intent: 'success'
	};
	return { type: alertConstants.SUCCESS, alert };
}

function error(message) {
	let alert = {
		message: message,
		icon: 'warning-sign',
		intent: 'danger'
	};
	return { type: alertConstants.ERROR, alert };
}

function warn(message) {
	let alert = {
		message: message,
		icon: 'hand',
		intent: 'warning'
	};
	return { type: alertConstants.ERROR, alert };
}

function info(message) {
	let alert = {
		message: message,
		icon: 'info-sign',
		intent: 'primary'
	};
	return { type: alertConstants.INFO, alert };
}

function clear() {
	return { type: alertConstants.CLEAR };
}

function createAlert(severity, message) {
	return (dispatch) => {
		switch (severity) {
			case 'success':
				dispatch(success(message));
				break;
			case 'error':
				dispatch(error(message));
				break;
			case 'warn':
				dispatch(warn(message));
				break;
			case 'info':
				dispatch(info(message));
				break;
			case 'clear':
				dispatch(clear());
				break;
			default:
				dispatch(clear());
				break;
		}
	};
}
