import React from 'react';
import { Grid, makeStyles, TextField, Box, Button } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    maingrid: {
        width: '100%',
        margin: '0px'
    },
    accordionMediaHeader: {
        display: 'flex'
    },
    transactions: {
        textAlign: 'left',
        width: '100%',
        height: '100%'
    },
    customerinfo: {
        textAlign: 'left',
        width: '100%',
        height: '100%'
    },

    metabox: {
        textAlign: 'left',
        width: '100%',
        marginBottom: '10px'
    },
    fab: {
        right: theme.spacing(1),
        margin: theme.spacing(0.5),
        color: '#fff',
        backgroundColor: '#0081f2'
    }
}));

export const InvoiceTransactionForm = (props) => {
    const classes = useStyles();
    const { transaction, editMode } = props;
    const { handleChangeEvent, handleFloatChangeEvent } = props;
    const isReadonly = editMode == false ? true : false;


    return (
        <Grid container xs={12}>
            <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                    <Box p={1}>
                        <TextField
                            name="paidby"
                            label="PaidBy"
                            multiline
                            maxRows={2}
                            value={transaction.paidby ? transaction.paidby : ''}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            name="amountpaid"
                            label="AmountPaid"
                            maxRows={1}
                            value={transaction.amountpaid ? transaction.amountpaid : ''}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            onChange={handleChangeEvent}
                            variant="outlined"
                        />
                    </Box>
                    <Box p={1}>
                        <Button>

                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};
