//InventoryItemDocumentsView
import React, { useState } from 'react';
import lodash from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles, Grid, Button } from '@material-ui/core';
import 'braft-editor/dist/index.css';
import { authHeader } from '../../helpers';
import { toCacheNotes, fromCacheNotes } from '../../helpers/util';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../../helpers/alert';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from 'draftail';
import createCounterPlugin from '@draft-js-plugins/counter';
const counterPlugin = createCounterPlugin();
const { CharCounter } = counterPlugin;
const plugins = [counterPlugin];

const BR_ICON = 'M.436 633.471l296.897-296.898v241.823h616.586V94.117h109.517v593.796H297.333v242.456z';
const UNDO_ICON =
	'M496.485 78c-137.092 0-261.213 55.575-351.046 145.439L.031 78v372.364h372.364L233.224 311.193c67.398-67.398 160.488-109.072 263.292-109.072 205.638 0 372.364 166.726 372.364 372.364 0 111.212-48.78 211.037-126.077 279.273l82.107 93.09C927.992 855.868 993 722.778 993 574.485 993 300.27 770.73 78 496.517 78h-.031z';
const REDO_ICON =
	'M0 576c0 152.928 67.04 290.176 173.344 384l84.672-96C178.304 793.632 128 690.688 128 576c0-212.064 171.936-384 384-384 106.048 0 202.048 42.976 271.52 112.48L640 448h384V64L874.016 213.984C781.376 121.312 653.376 64 512 64 229.216 64 0 293.216 0 576z';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		flexGrow: 1,
		backgroundColor: '#9e9e9e27',
		textAlign: 'left',
		justifyContent: 'space-between'
	},
	savebutton: {
		marginTop: '4px',
		marginBottom: '4px',
		//color: '#fff',
		backgroundColor: '#0081f2'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	midGridStyle: { minHeight: '100px', backgroundColor: 'transparent', marginBottom: '10px' }
}));

export const InventoryItemNotesViewDT = (props) => {
	const classes = useStyles();
	const { item } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [changed, setChanged] = useState(false);
	const noteskey = item.rid + '_notes';
	const notescharmax = 1000;
	const initial = fromCacheNotes(noteskey);

	async function submitNotes() {
		//if change has happened, only then do the save to backedn
		if (!changed) return;
		//const notesData = sessionStorage.getItem(item.rid);

		const notesData = fromCacheNotes(noteskey);
		if (!notesData) return;

		//if the note is larger than max characters, alert the user
		//TODO: Add a character limit
		/*
		let notesDataLength = JSON.stringify(notesData)     

		if (notesData.length > notescharmax) {
			enqueueSnackbar('Error, note is longer than allowed ' + notescharmax + ' characters', ALERT_VARIANT_ERROR);
		}
		*/

		const notesUpdateUrl = '/notes/' + item.rid;
		let headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/json',
			'Content-Length': notesData ? notesData.length : 0
		};
		try {
			axios.post(notesUpdateUrl, notesData, headers).then((response) => {
				setChanged(false);
				enqueueSnackbar('Note saved successfully', ALERT_VARIANT_SUCCESS);
			});
		} catch (error) {
			enqueueSnackbar('Error saving note', ALERT_VARIANT_ERROR);
		}
	}
	const onSave = (content) => {
		//TODO: find a better way to initialize state from api call data
		if (!content) return;

		const isChanged = !lodash.isEqual(content, fromCacheNotes(noteskey));
		if (isChanged) {
			toCacheNotes(noteskey, content);
			setChanged(isChanged);
		}
	};

	return (
		<div name="inventoryitemnotesview">
			<Grid container direction="row" className={classes.midGridStyle}>
				<Grid container item xs={12} className={classes.toolbar}>
					<Grid item xs={10}>
						<Button
							variant="contained"
							color="primary"
							onClick={submitNotes}
							startIcon={<SaveIcon />}
							className={classes.savebutton}
						>
							Save Notes
						</Button>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center">
					<Grid item xs={12}>
						<div className={classes.editorwindow} name="editorwindow">
							<DraftailEditor
								plugins={plugins}
								rawContentState={initial || null}
								enableHorizontalRule={{
									description: 'Horizontal rule'
								}}
								enableLineBreak={{
									description: 'Soft line break',
									icon: BR_ICON
								}}
								showUndoControl={{
									description: 'Undo last change',
									icon: UNDO_ICON
								}}
								showRedoControl={{
									description: 'Redo last change',
									icon: REDO_ICON
								}}
								onSave={onSave}
								blockTypes={[
									{ type: BLOCK_TYPE.HEADER_THREE },
									{ type: BLOCK_TYPE.HEADER_FOUR },
									{ type: BLOCK_TYPE.UNORDERED_LIST_ITEM, icon: <FormatListBulletedIcon /> },
									{
										type: BLOCK_TYPE.ORDERED_LIST_ITEM,
										icon: <FormatListNumberedIcon />
									}
								]}
								inlineStyles={[
									{ type: INLINE_STYLE.BOLD },
									{ type: INLINE_STYLE.ITALIC },
									{ type: INLINE_STYLE.STRIKETHROUGH },
									{ type: INLINE_STYLE.UNDERLINE }
								]}
							/>
						</div>
						<div>
							<CharCounter limit={notescharmax} /> characters (max <b>{notescharmax})</b>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
