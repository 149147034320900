import React, { useEffect } from 'react';
import { getWithExpiry, setWithExpiry } from '../helpers/util';
import lodash from 'lodash';
import {
	Button,
	makeStyles,
	Paper,
	Card,
	CardContent,
	CardActions,
	Grid,
	CardHeader
} from '@material-ui/core';
import { authHeader } from '../helpers';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_ERROR } from '../helpers/alert';
import axios from 'axios';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
	VinDataContainer: {
		display: 'block',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	VinSearchBar: {
		display: 'flex',
		justifyContent: 'center'
	},
	VinSearchInput: {
		width: '70%',
		[theme.breakpoints.up('md')]: {
			width: '30%'
		}
	},
	VinSearchButton: {
		backgroundColor: '#0081f2',
		color: '#fff'
	},
	VinSearchSubHeader: {
		display: 'flex',
		justifyContent: 'center'
	},
	VinData: {
		justifyContent: 'center',
		backgroundColor: '#cac1c017',
		color: '#000'
	},
	VinDataTag: {
		fontSize: '1.1em',
		color: '#00000075',
		paddingRight: '10px',
		textAlign: 'right'
	},
	VinDataValue: {
		fontSize: '1.1em',
		color: '#000',
		paddingLeft: '10px',
		textAlign: 'left'
	},
	paper: {
		width: '100%',
		display: 'flex',
		padding: '2px',
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	dontBreakOut: {
		/* These are technically the same, but use both */
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
		msWordBreak: 'break-all',
		/* Instead use this non-standard one: */
		wordBreak: 'break-word',
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		msHyphens: 'auto',
		mozHyphens: 'auto',
		webkitHyphens: 'auto',
		hyphens: 'auto'
	}
}));

export const ResearchPage = (props) => {
	const defaultVin = lodash.get(props.history.location.state, 'vin', '');
	const classes = useStyles();
	const [ vinData, setVinData ] = React.useState(null);
	const [ vin, setVin ] = React.useState(defaultVin);
	const { enqueueSnackbar } = useSnackbar();
	const VinCacheTTLMsec = 30 * 86400000; //30 days
	const getVinFromServer = () => {
		try {
			//console.log('getting vin data for ', vin);
			const headers = {
				headers: {
					...authHeader()
				},
				'Content-Type': 'application/json'
			};
			const vinUrl = '/vin/' + vin;
			axios.get(vinUrl, headers).then((response) => {
				setWithExpiry(vin, response.data, VinCacheTTLMsec);
				setVinData(response.data);
			});
		} catch (error) {
			enqueueSnackbar('error getting vin data', ALERT_VARIANT_ERROR);
			//console.log(error);
			//TODO: Show error toast
		}
	};
	const searchVin = (event) => {
		if (event) {
			event.stopPropagation();
		}
		if (vin) {
			//console.log('searching for vin [', vin, ']');
			//1. Look inside localstorage
			let vd = getWithExpiry(vin);

			//the vin data may have expired in the localstorage, attemp to pull from server
			if (!vd) {
				getVinFromServer();
			} else {
				setVinData(vd);
			}
		}
	};

	const handleVinChange = (event) => {
		event.stopPropagation();
		const value = event.target.value;
		setVin(value);
	};

	const FormRow = ({ name, value }) => {
		return (
			<Paper className={classes.paper}>
				<Grid item xs={5}>
					<p className={clsx(classes.dontBreakOut, classes.VinDataTag)}>{name}</p>
				</Grid>
				<Grid item xs={7}>
					<p className={clsx(classes.dontBreakOut, classes.VinDataValue)}>{value}</p>
				</Grid>
			</Paper>
		);
	};

	useEffect(
		() => {
			searchVin(null);
		},
		[ vin ]
	);

	const renderVindData = () => {
		if (!vinData) {
			//console.log('vin data not available');
			//TODO: Show error toast
			return;
		}
		return (
			<div className={classes.VinData}>
				<Grid container spacing={1}>
					<FormRow name="Vin" value={vinData.VIN} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="Make" value={vinData.Make} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="Model" value={vinData.Model} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="ModelYear" value={vinData.ModelYear} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="Trim" value={vinData.Trim} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="VehicleType" value={vinData.VehicleType} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="Doors" value={vinData.Doors} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="BodyClass" value={vinData.BodyClass} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="PlantCity" value={vinData.PlantCity} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="PlantCountry" value={vinData.PlantCountry} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="AirBagLocFront" value={vinData.AirBagLocFront} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="AirBagLocSide" value={vinData.AirBagLocSide} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="SeatBeltsAll" value={vinData.SeatBeltsAll} />
				</Grid>
				<Grid container spacing={1}>
					<FormRow name="OtherRestraintSystemInfo" value={vinData.OtherRestraintSystemInfo} />
				</Grid>
			</div>
		);
	};
	return (
		<Card className={classes.VinDataContainer} raised={false} elevation={0}>
			<CardHeader
				title={
					<div className={classes.VinSearchBar}>
						<input value={vin} onChange={handleVinChange} className={classes.VinSearchInput} type="text" />
						<Button className={classes.VinSearchButton} variant="contained" onClick={searchVin}>
							Search
						</Button>
					</div>
				}
				subheader={<div className={classes.VinSearchSubHeader}>search vin information</div>}
			/>
			<CardContent>{renderVindData()}</CardContent>
			<CardActions />
		</Card>
	);
};
