import React, { useState } from 'react';
import { Card, CardActions, CardContent, Grid, makeStyles } from '@material-ui/core';
import { InventoryItemDetailedView } from './InventoryItemDetailedView';
import { InventoryItemSimplifiedView } from './inventoryItemSimplifiedView';
import { InventoryItemCreateForm } from './InventoryItemCreateForm';
import { defaultTagSearchFilters } from '../../helpers';

import { useDispatch } from 'react-redux';
import { inventoryConstants } from '../../constants';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		height: '100%',
		background: '#fff',
		overflow: 'auto'
	},
	paperedit: {
		width: '100%',
		height: '100%',
		background: '#ffeb3b14'
	}
}));
export const InventoryItem = ({ item, viewMode }) => {
	if (!viewMode) {
		viewMode = 'dense';
	}
	const classes = useStyles();
	const [selectedItem, setSelectedItem] = useState(item);
	const [editMode, setEditMode] = useState(
		selectedItem.isnew ? (selectedItem.isnew === true ? true : false) : false
	);
	const dispatch = useDispatch();

	const newItem = () => {
		return <InventoryItemCreateForm item={selectedItem} onResetSelectedItem={onResetSelectedItem} />;
	};

	const performTagSearch = (search) => {
		//console.log('search=', search, 'searchFilters=');
		const searchQuery = search;
		//NOTE: Skipping the redux update for now, since the Header will always be present and will hold the latest search filter
		//inventoryActions.searchinventory(finalQuery);
		dispatch({
			type: inventoryConstants.INVENTORY_SEARCH_REQUEST,
			payload: { search: searchQuery, filters: defaultTagSearchFilters }
		});
	};

	const existingItem = () => {
		if (viewMode === 'dense') {
			return <InventoryItemSimplifiedView item={selectedItem} tagSearch={performTagSearch} />;
		} else {
			return <InventoryItemDetailedView item={selectedItem} tagSearch={performTagSearch} />;
		}
	};

	// const onSetSelectedItem = (event) => {
	// 	//NOTE: skip if the current item is already selected
	// 	dispatch({
	// 		type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
	// 		payload: item
	// 	});

	// 	event.stopPropagation();
	// };

	const onResetSelectedItem = (newitem) => {
		dispatch({
			type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
			payload: newitem
		});
	};

	return (
		<Card
			className={editMode === true ? classes.paperedit : classes.paper}
			elevation={2}
		//onClick={onSetSelectedItem}
		>
			<CardContent>
				<Grid item container xs={12} md={12}>
					<Grid item xs={12} md={12} />
					{selectedItem.isnew ? newItem() : existingItem()}
				</Grid>
			</CardContent>
			<CardActions />
		</Card>
	);
};
