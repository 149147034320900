import axios from 'axios';
export const maxFileUploadMB = 2; //2MB per file
export const maxFileUploadBytes = maxFileUploadMB * 1024 * 1024; //per file
export const graphqlURL = process.env.REACT_APP_EMOTORON_GRAPHQL_URL;
export const graphqlPollInterval = 30000; //30 seconds
export const baseURL = process.env.REACT_APP_EMOTORON_API_URL;
export const emotoronVersion = process.env.REACT_APP_EMOTORON_WEB_VER;
axios.defaults.baseURL = baseURL; // the prefix of the URL
axios.defaults.headers.get['Accept'] = 'application/json'; // default header for all get request
axios.defaults.headers.post['Accept'] = 'application/json'; // default header for all POST request

// Create instance called instance
export const API = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json'
		// 'x-emotoronapi-host': 'emotoron.com',
		// 'x-emotoronapi-key': process.env.EMOTORON_API_KEY
	}
});

export const defaultSearchFilters = {
	general: true,
	usetagsearch: false,
	includedeleted: false
};

export const defaultTagSearchFilters = {
	general: false,
	usetagsearch: true,
	includedeleted: false
};

// export const SECAPI = axios.create({
// 	baseURL: baseURL,
// 	headers: {
// 		...authHeader(),
// 		'Content-Type': 'application/json'
// 		//'x-emotoronapi-host': 'emotoron.com',
// 		//'x-emotoronapi-key': process.env.EMOTORON_API_KEY
// 	}
// });
