import React from 'react';
import { connect } from 'react-redux';

import { userActions } from '../actions';

import { Button, FormGroup, Label, Input } from 'reactstrap';
import { Grid } from '@material-ui/core';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		// reset login status
		//this.props.logout();

		this.state = {
			username: '',
			password: '',
			submitted: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		//console.log('LoginPage did mount');
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ submitted: true });
		const { username, password } = this.state;
		if (username && password) {
			this.props.login(username, password);
			this.setState({ username: '', password: '' });
		}
	}

	render() {
		const { loggingIn, loginErrors } = this.props;
		const { username, password } = this.state;
		return (
			<Grid container spacing={0} justifyContent="center" direction="row" style={{ minHeight: '90vh' }}>
				<Grid container direction="column" justifyContent="center" alignItems="center" md={12} lg={12}>
					<Grid item>
						<h1 className="font-weight-bold">eMotoron</h1>
					</Grid>
					<Grid item>
						<h2 className="text-center">Login</h2>
						<br />
					</Grid>
					<Grid item>
						<FormGroup>
							<Label className="control-label" for="username">
								Username
							</Label>
							<Input
								id="username"
								name="username"
								placeholder="username"
								autoCapitalize="none"
								value={username}
								onChange={this.handleChange}
								autoFocus={true}
								required
							/>
						</FormGroup>
					</Grid>
					<Grid item>
						<FormGroup>
							<Label className="control-label" for="password">
								Password
							</Label>
							<Input
								id="password"
								name="password"
								placeholder="password"
								type="password"
								value={password}
								onChange={this.handleChange}
								required
							/>
						</FormGroup>
					</Grid>
					<Grid item style={{ minWidth: '200px' }}>
						<Button color="primary" size="lg" block onClick={this.handleSubmit}>
							Log in
						</Button>
						{loggingIn && (
							<img alt="spinner" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						)}
					</Grid>
					<Grid item>
						<div className="text-center">
							<a href="/login">Register</a>
							<span className="p-2">|</span>
							<a href="/login">Forgot Password</a>
						</div>
					</Grid>
					<Grid>{loginErrors && <div>Login error: try again!</div>}</Grid>
				</Grid>
			</Grid>
		);
	}
}

function mapState(state) {
	const { loggingIn, loginErrors } = state.authentication;
	return { loggingIn, loginErrors };
}

const actionCreators = {
	login: userActions.login,
	logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
