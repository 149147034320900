import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { InventoryItemList, InventoryItemTable } from '../components/inventory';
import Loader from '../components/Loader';
import { InventoryHeader, InventoryTagsBar } from '../components/inventory';
import { InventoryItem } from '../components/inventory/InventoryItem';
import { GQL_GET_INVENTORYITEMS } from '../gqlqueries';
import { defaultSearchFilters, graphqlPollInterval, SanitizeInvoiceObject } from '../helpers';
import { useDispatch } from 'react-redux';
import { inventoryConstants, salesConstants } from '../constants';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../helpers/alert';
import { InvoiceFormView } from '../components/sales/InvoiceFormView';
import { ApiCreateInvoice, ApiUpdateInvoice, ApiGetInvoice } from '../api';
const useStyles = makeStyles((theme) => ({
	inventorycontainer: {
		width: '100%',
		margin: '0px'
	},
	box: {
		width: '100%',
		padding: theme.spacing(1),
		margin: '2px'
	},
	inventoryroot: {
		backgroundColor: '#fff'
	}
}));

const InventoryPage = (props) => {
	const inventory = useSelector((state) => state.inventory);
	const sales = useSelector((state) => state.sales);
	const { enqueueSnackbar } = useSnackbar();
	const [sortOption, setSortOption] = useState('Featured');
	const [inventoryItemsView, setInventoryItemsView] = useState('table');
	//inventoryView has two possible values: 'inventory' and 'itemdetails'
	//const [ inventoryView, setInventoryView ] = useState('inventory');

	//possible filter values: featured, purchasepricelow, purchasepricehigh, purchasedateold, purchasedatanew
	const sortOptionChanged = (thechange) => {
		setSortOption(thechange.target.value);
	};

	const onInventoryItemsViewChange = (thechange) => {
		setInventoryItemsView(thechange);
	};
	const dispatch = useDispatch();
	const classes = useStyles();
	const search = inventory ? inventory.searchquery : '';
	const filters = inventory ? inventory.searchfilters : defaultSearchFilters;
	const selectedItem = inventory ? inventory.selectedItem : null;
	const selectedInvoiceItem = sales ? sales.selectedInvoice : null;
	//NOTE: if there is a selected item, then we will show the details view
	//NOTE: on the return view, the select item will be reset which should bring up the inventory view
	// if (selectedItem) {
	// 	setInventoryView('itemdetails');
	// }
	const inventoryView = selectedItem ? 'itemdetails' : 'inventory';
	//const { loading, error, data } = useQuery(GET_INVENTORYITEMS);
	//console.log(filters);

	const { loading, error, data, refetch } = useQuery(GQL_GET_INVENTORYITEMS, {
		variables: { search: search, filters: JSON.stringify(filters) },
		pollInterval: graphqlPollInterval,
		errorPolicy: 'all'
	});

	if (!selectedItem && !error) {
		refetch().catch((e) => null);
	}

	const getTagsFromResults = (newData) => {
		let tags = [];
		if (!newData) return tags;

		tags = newData.map((item) => {
			const subtags = [...item.tags];
			return subtags.map((st) => {
				if (!tags.includes(st)) {
					tags.push(st);
				}
			});
		});
		return tags;
	};
	const getInventoryView = () => {
		return (
			<div name="getinventoryview" className={classes.inventorycontainer}>
				{loading && <Loader />}
				{error && <p style={{ color: 'red', fontWeight: '600' }}>Error loading data from server...</p>}
				{data &&
					(inventoryItemsView === 'list' ? (
						<div>
							<InventoryTagsBar
								tags={getTagsFromResults(data.inventoryitems)}
								tagSearch={performTagSearch}
							/>
							<InventoryItemList items={data.inventoryitems} />
						</div>
					) : (
						<div>
							<InventoryTagsBar
								tags={getTagsFromResults(data.inventoryitems)}
								tagSearch={performTagSearch}
							/>
							<InventoryItemTable items={data.inventoryitems} />
						</div>
					))}
			</div>
		);
	};

	const getDetailView = () => {
		return (
			<div name="getdetailview" className={classes.inventorycontainer}>
				<Box className={classes.box}>
					{selectedItem && <InventoryItem item={selectedItem} viewMode="detailed" />}
				</Box>
			</div>
		);
	};

	const performTagSearch = (search) => {
		if (!search) {
			search = '';
		}
		dispatch({
			type: inventoryConstants.INVENTORY_SEARCH_REQUEST,
			payload: { search: search, filters: defaultSearchFilters }
		});
	};

	const onRefreshData = () => {
		performTagSearch('');
	};

	const onCloseInvoiceForm = () => {
		setSelectedInvoice(null)
	};

	const setSelectedInvoice = (invoice) => {
		console.log("the selected invoice is ", invoice)
		dispatch({
			type: salesConstants.SALES_ITEM_SELECT_REQUEST,
			payload: invoice
		});
	}

	const onInvoiceFormSubmissionSuccess = (response) => {
		enqueueSnackbar('Invoice saved successfully', ALERT_VARIANT_SUCCESS);

		onCloseInvoiceForm()
	}

	const onInvoiceFormSubmissionFailure = (response) => {
		enqueueSnackbar('Error saving invoice', ALERT_VARIANT_ERROR);
	}

	const onUpdate = () => {
		console.log('refreshing invoice from server')
		if (selectedInvoiceItem) {
			ApiGetInvoice(selectedInvoiceItem.id, setSelectedInvoice)
		}
	}

	const onSubmitInvoiceForm = (modifiedItem) => {
		const sanitizedInvoice = SanitizeInvoiceObject(modifiedItem)
		//1. Submit the invoice form through the api
		if (sanitizedInvoice.isnew === true) {
			ApiCreateInvoice(sanitizedInvoice, onInvoiceFormSubmissionSuccess, onInvoiceFormSubmissionFailure);
		} else {
			ApiUpdateInvoice(sanitizedInvoice, onInvoiceFormSubmissionSuccess, onInvoiceFormSubmissionFailure);
		}
	}

	const showSelectedInvoiceForm = () => {
		//console.log('In showSelectedInvoiceForm')
		if (!selectedInvoiceItem) return
		return (
			<InvoiceFormView
				item={selectedInvoiceItem}
				open={!!selectedInvoiceItem}
				onUpdate={onUpdate}
				onSubmitInvoiceForm={onSubmitInvoiceForm}
				onCloseInvoiceForm={onCloseInvoiceForm}
			/>
		);
	}

	return (
		<div name="inventorypage" className={classes.inventoryroot}>
			<InventoryHeader
				data={data ? data.inventoryitems : []}
				inventoryView={inventoryView}
				sortOptionChanged={sortOptionChanged}
				selectedSortOption={sortOption}
				inventoryItemsView={inventoryItemsView}
				onInventoryItemsViewChange={onInventoryItemsViewChange}
				onRefreshData={onRefreshData}
			/>
			{/* <Grid container spacing={1} className={classes.grid}> */}
			{/* 
				//NOTE: The left and right side panes are being disabled for now
				<Hidden smDown>
					<Grid item md={2}>
						{leftSideContent()}
					</Grid>
				</Hidden> */}
			{inventoryView === 'inventory' ? getInventoryView() : getDetailView()}
			{/* </Grid> */}
			{showSelectedInvoiceForm()}
		</div>
	);
};

export { InventoryPage };

//NOTES:
//1/22/2021:
// - Removing the right pane for now
// - Can be reintroduced to host optional widgets
// - When reintroducing the right pane, the breakpoints on middle pane need to be set accordingly
