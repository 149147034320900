import gql from 'graphql-tag';
export const GQL_GET_INVENTORYITEMS = gql`
	query getInventoryItems($search: String, $filters: String) {
		inventoryitems(search: $search, filters: $filters) {
			rid
			eid
			title
			saleitemid
			description
			details
			images
			docs
			tags
			modifiedtime
			deleted
		}
	}
`;


export const GQL_GET_SALEITEMS = gql`
	query getSaleItems($search: String, $filters: String) {
		saleitems(search: $search, filters: $filters) {
			id
			rid
			eid
    		itemid
			itemtitle
			status
			username
			approvedby
			taxpercent
			fees
    		quotedprice    		
			totalcost
			totalpaid
			modifiedtime
			salenotes
			deleted
			customerinfo
			transactions
		}
	}
`;
