import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
//import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { authHeader, graphqlURL } from '../helpers';

const delay = setContext(
	(request) =>
		new Promise((success, fail) => {
			setTimeout(() => {
				success();
			}, 800);
		})
);

const httpLink = new HttpLink({ uri: graphqlURL, credentials: 'same-origin' });
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists	
	const authHeaders = authHeader()
	
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			...authHeaders
		}
	};
});

const client = new ApolloClient({
	//link: authLink.concat(link),
	link: from([ delay, authLink, errorLink, httpLink ]),
	cache: new InMemoryCache()
});

export { client as gqlclient };

//REFERENCE
//https://www.apollographql.com/docs/react/networking/authentication/
