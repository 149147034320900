import axios from 'axios';
import React from 'react';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { authHeader } from '../helpers';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../helpers/alert';
import { makeStyles } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
const useStyles = makeStyles((theme) => ({
	deleteButton: {
		color: 'red'
		// background: '#f50057'
	}
}));
export const DeleteFile = (props) => {
	const { item, mediaType, selectedFile, handleClose, onChange, iconOnly, extData, ...other } = props;
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	//console.log('inside DeleteFile, selectedFile is ', selectedFile);
	const deleteFile = () => {
		//if this is a default image, then skip deletion
		if (!selectedFile.id) {
			return;
		}
		try {
			const url = '/' + mediaType + '/' + item.rid + '/' + selectedFile.id + '/' + selectedFile.src;
			//const url = '/' + mediaType + '/' + item.rid + '/' + selectedFile.src;
			const headers = {
				headers: {
					...authHeader()
				}
			};
			//console.log(url);
			axios
				.delete(url, headers)
				.then((response) => {
					enqueueSnackbar('deleted ' + selectedFile.src, ALERT_VARIANT_SUCCESS);
					if (handleClose) {
						handleClose();
					}

					if (onChange) {
						onChange();
					}
				})
				.catch((error) => {
					enqueueSnackbar('error deleting files', ALERT_VARIANT_ERROR);
					//console.log(error);
				});
		} catch (error) {
			enqueueSnackbar('error deleting files', ALERT_VARIANT_ERROR);
			//console.log(error);
		}
	};

	return (
		<span>
			<Button
				{...other}
				disabled={extData}
				aria-label="upload files"
				className={classes.deleteButton}
				onClick={(event) => {
					deleteFile();
				}}
			>
				{iconOnly ? <RemoveCircleOutlineIcon /> : 'Delete Image'}
			</Button>
		</span>
	);
};
