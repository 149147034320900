import React from 'react'
import { Grid, Box, Typography, TextField, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    detailgrid: {
        textAlign: 'left',
        width: '100%',
        height: '100%'
    },
    metabox: {
        textAlign: 'left',
        width: '100%',
        marginBottom: '10px'
    }
}));

export const UserProfileForm = (props) => {
    const classes = useStyles();
    const { userprofile, editMode, handleChangeEvent } = props


    const isReadonly = editMode == false ? true : false;
    return (
        <Grid container xs={12}>
            <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" style={{ fontWeight: '600' }} gutterBottom>
                    User Information
                </Typography>
                <Box display="flex" flexWrap="wrap" p={0} m={0} className={classes.metabox}>
                    <Box p={1}>
                        <TextField
                            name="name"
                            label="Name"
                            multiline
                            maxRows={2}
                            value={userprofile.name}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                    {/* <Box p={1}>
                        <TextField
                            name="email"
                            label="Email"
                            maxRows={1}
                            value={"ibrahim.noorzaie@emotoron.com"}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            readOnly
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box> */}
                    <Box p={1}>
                        <TextField
                            name="phone"
                            label="Phone"
                            maxRows={1}
                            value={userprofile.phone}
                            onChange={handleChangeEvent}
                            InputProps={{
                                readOnly: isReadonly
                            }}
                            readOnly
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Box>
                </Box>
            </Grid>
            <Divider variant="middle" />
        </Grid>
    )
}