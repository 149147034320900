import { alertConstants } from '../constants';

export function alert(state = {}, action) {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return action.alert;
		case alertConstants.ERROR:
			return action.alert;
		case alertConstants.INFO:
			return action.alert;
		case alertConstants.CLEAR:
			return {};
		default:
			return state;
	}
}
