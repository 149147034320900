import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
export async function ApiGetInvoice(invoiceId, responseHandler) {
	let headers = {
		headers: {
			...authHeader()
		},
		'Content-Type': 'application/json'
	};

	//notes are available under docs
	const url = '/sales/' + invoiceId;

	try {
		const response = await axios.get(url, headers);
        if(responseHandler){
		    responseHandler(response.data);
        }
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export async function ApiCreateInvoice(invoice, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    //get the notes from localstorage
    const url = '/sales';
    try {
        const response = await axios.post(url, invoice, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}



export async function ApiUpdateInvoice(invoice, onSuccess, onFailure) {
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };
    //get the notes from localstorage    
    const url = '/sales/' + invoice.id;
    try {
        const response = await axios.put(url, invoice, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }
}

export async function addInvoiceTransaction(transaction, onSuccess, onFailure) {
    return setInvoiceTransaction(transaction, "add", onSuccess, onFailure)
}


export async function deleteInvoiceTransaction(transaction, onSuccess, onFailure) {
    return setInvoiceTransaction(transaction, "delete", onSuccess, onFailure)
}

export async function updateInvoiceTransaction(transaction, onSuccess, onFailure) {
    return setInvoiceTransaction(transaction, "update", onSuccess, onFailure)
}

export async function setInvoiceTransaction(transaction, action, onSuccess, onFailure) {
    //http://localhost:8095/sales/c5ai8iecie6m50d4pudg/tr
    let headers = {
        headers: {
            ...authHeader()
        },
        'Content-Type': 'application/json'
    };

    let data = {
        action: action,
        chargetransaction: transaction
    }
    //get the notes from localstorage
    const url = '/sales/' + transaction.invoiceid + '/tr';
    try {
        const response = await axios.put(url, data, headers);
		onSuccess(response.data);
    } catch (error) {
        onFailure(error)
    }    
}


export const prepareInvoiceDownloadRequest = (id) => {
    const apiUrl = '/sales/' + id + '/print';
    
    let data = {};
    const filename = ('invoice' + '_' + id + '.pdf');
    //TODO: add a date and time (upto seconds) in the filename
    const request = {
        url: apiUrl,
        httpHeaders: {
            ...authHeader()
        },
        filename: filename,
        data: data,
        action: 'POST'
    };

    return request;
};