import React, { useState, useEffect, useRef } from 'react';
import { salesConstants } from '../../constants';
import { InventoryItemDocumentsView } from './InventoryItemDocumentsView';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NotesIcon from '@material-ui/icons/Notes';
import { InventoryItemImagesView } from './InventoryItemImagesView';
import { InventoryItemNotesViewDT } from './InventoryItemNotesViewDT';
import { updateInventoryItemNotes } from '../../helpers/util';
import { Box, makeStyles, Divider, Tabs, Tab } from '@material-ui/core';
import { InventoryItemHeader } from './InventoryItemHeader';
import { useDispatch, useSelector } from 'react-redux';
import { toCacheNotes } from '../../helpers/util';
import { getUserEID } from '../../helpers';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { authHeader } from '../../helpers';
import axios from 'axios';
import { InventoryItemDetailsForm } from './InventoryItemDetailsForm';
import { ApiGetInvoice } from '../../api';
import { NewInvoiceObject } from '../../helpers/model'

const useStyles = makeStyles((theme) => ({
	item: {
		textAlign: 'center',
		margin: '2px',
		borderRadius: '2px'
	},
	media: {
		height: '50%',
		width: 'auto',
		paddingBottom: '10px',
		paddingTop: '10px'
	},
	mainimage: {
		maxHeight: '300px',
		width: '70%',
		objectFit: 'contain'
	}
}));

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

export const InventoryItemDetailedView = (props) => {
	//console.log('InventoryItemDetailedView is being rendered');
	const classes = useStyles();
	const { item } = props;
	const [selectedItem, setSelectedItem] = useState({ ...item });

	const [tabValue, setTabValue] = useState(0);
	const dispatch = useDispatch();
	const [editMode, setEditMode] = useState(
		selectedItem.isnew ? (selectedItem.isnew === true ? true : false) : false
	);
	const inventory = useSelector((state) => state.inventory);
	// const searchquery = inventory ? inventory.searchquery : '';

	const viewMode = 'detail';

	const handleTabChange = (event, newValue) => {
		if (newValue !== tabValue) {
			setTabValue(newValue);
		}
	};

	const noteskey = item.rid + '_notes';
	//receives raw content and saves as json in cache( sessionStorage )

	const getInventoryItemNotes = (rid) => {
		//console.log('getting notes from localstorage');
		//TODO: for now disabling pulling from cache on item selection.
		//let notes = fromCacheNotes(noteskey);
		//if (!notes) {
		//let check the server
		try {
			pullItemNotesDataFromServer(rid)
				.then((notes) => {
					//update cache as well
					toCacheNotes(noteskey, notes);
					//setInitialState(fromCacheNotes(noteskey));
					return notes;
				})
				.error((err) => {
					console.log('error getting notes from server');
				});

			//the pull would have updated the local storage so try it again
		} catch (error) {
			return null;
		}
		// } else {
		// 	return notes;
		// }
	};

	async function pullItemNotesDataFromServer(rid) {
		//console.log('in getItemNotesDataFromServer');
		let headers = {
			headers: {
				...authHeader()
			},
			'Content-Type': 'application/json'
		};

		//notes are available under docs
		const notesUrl = '/doc/' + rid + '/' + rid + '_notes.json';

		let response;
		try {
			response = await axios.get(notesUrl, headers);
			// console.log('response from server is ', response);
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}


	const setSelectedInvoice = (invoice) => {
		//console.log("the selected invoice is ", invoice)
		dispatch({
			type: salesConstants.SALES_ITEM_SELECT_REQUEST,
			payload: invoice
		});
	}

	const onShowInVoice = (item) => {
		//NOTE: If item.saleitemid is empty, this means there is no invoice and a new invoice will be started
		//console.log('get invoice id ', item.saleitemid)
		if (!item.saleitemid) {
			console.log('an invoice is not available, so we will show the new invoice view')
			//an invoice is not available, we will create a new invoice
			let newInvoice = NewInvoiceObject(item)
			setSelectedInvoice(newInvoice)
		} else {
			//console.log('pulling invoice from the server')
			ApiGetInvoice(item.saleitemid, setSelectedInvoice)
		}
	}

	useEffect(
		() => {
			//setSelectedItem({ ...selectedItem });
			getInventoryItemNotes(item.rid);
		},
		[selectedItem]
	);

	const isReadonly = editMode === false ? true : false;
	const extData = getUserEID() !== item.eid;
	//console.log('InventoryItemDetailedView extData=', extData);
	return (
		<Box className={classes.item}>
			<InventoryItemHeader
				item={selectedItem}
				viewMode={viewMode}
				editMode={editMode}
				extData={extData}
				showDelete={true}
				onClickHeader={() => { }}
			/>
			<Tabs value={tabValue} onChange={handleTabChange} aria-label="media tabs">
				<Tab label="Images" icon={<PhotoCameraIcon color="primary" />} {...a11yProps(0)} />
				<Tab
					style={{ display: extData ? 'none' : 'block' }}
					disabled={extData}
					label="Invoice"
					icon={<BusinessRoundedIcon color="primary" />}
					onClick={(event) => {
						onShowInVoice(item);
						event.stopPropagation();
					}}
					{...a11yProps(1)}
				/>
				<Tab
					style={{ display: extData ? 'none' : 'block' }}
					disabled={extData}
					label="Documents"
					icon={<InsertDriveFileIcon color="primary" />}
					{...a11yProps(2)}
				/>
				<Tab
					style={{ display: extData ? 'none' : 'block' }}
					disabled={extData}
					label="Notes"
					icon={<NotesIcon color="primary" />}
					{...a11yProps(3)}
				/>
			</Tabs>
			<TabPanel value={tabValue} index={0} style={{ overflow: 'auto', padding: '2px' }} name="tabpanelforimages">
				<div className={classes.media}>
					<InventoryItemImagesView item={item} extData={extData} />
				</div>
			</TabPanel>
			<TabPanel value={tabValue} index={1} style={{ overflow: 'auto', padding: '2px' }} name="tabpanelfoinvoice" />
			<TabPanel value={tabValue} index={2} style={{ overflow: 'auto', padding: '2px' }} name="tabpanelforpdf">
				<div className={classes.media}>
					<InventoryItemDocumentsView item={selectedItem} />
				</div>
			</TabPanel>
			<TabPanel value={tabValue} index={3} style={{ overflow: 'auto', padding: '2px' }} name="tabpanelfornotes">
				<div className={classes.media}>
					<InventoryItemNotesViewDT item={selectedItem} isReadonly={isReadonly} />
				</div>
			</TabPanel>
			<Divider />
			<InventoryItemDetailsForm item={selectedItem} extData={extData} />
		</Box>
	);
};
