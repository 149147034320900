import React, { useState, useEffect } from 'react'
import { InvoiceForm } from './InvoiceForm'
import { makeStyles, Fab, Tooltip, Box, Button, CardHeader } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import lodash from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { AlertDialog } from '../AlertDialog';
import Toolbar from '@mui/material/Toolbar';
import CheckIcon from '@mui/icons-material/Check';
import { DownloadFile } from '../../components/DownloadFile';
import { prepareInvoiceDownloadRequest } from '../../api'
const useStyles = makeStyles((theme) => ({
    invoiceform: {
        overflow: 'auto',
        maxHeight: '300px',
        overflowX: 'scroll',
        textAlign: 'left'
    },
    transactions: {
        marginLeft: '5px'
    },
    buttonSubmit: {
        background: 'green'
    },
    buttonCancel: {
        background: 'gray'
    },
    fab: {
        backgroundColor: '#0081f2'
    },
    invoicepaid: {
        color: 'green',
        fontWeight: '600'
    },

    invoicepartial: {
        color: 'red',
        fontWeight: '600'
    }
}));

export const InvoiceFormView = (props) => {
    const { item, open, onSubmitInvoiceForm, onCloseInvoiceForm, onUpdate, ...other } = props
    const classes = useStyles();
    //const [selectedItem, setSelectedItem] = useState({ ...item });
    const [selectedItem, setSelectedItem] = useState({ ...item });
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [editMode, setEditMode] = useState(
        item.isnew ? (item.isnew === true ? true : false) : false
    );

    const getAvatar = () => {
        return (
            <h3>Invoice</h3>
        );
    };

    const onSetEditMode = (event) => {
        setEditMode(true);
        event.stopPropagation();
    };

    const saveChanges = (event) => {
        event.stopPropagation();
        setEditMode(false);
        console.log('saving invoice form')
        onSubmitInvoiceForm(selectedItem)
    };

    const onCancelChanges = (event) => {
        event.stopPropagation();
        setSelectedItem({ ...item });
        setEditMode(false);
    };

    useEffect(
        () => {
            setSelectedItem({ ...item });
            //console.log('item was changed');
        },
        [item]
    );



    const handleChangeEvent = (event) => {
        const { name, value } = event.target;
        handleChange(name, value);
    };

    const handlePriceChangeEvent = (event, currency) => {
        if (!currency) {
            currency = '$';
        }
        const { name, value } = event.target;
        const price = {
            value: value,
            currency: currency
        };
        handleChange(name, price);
    };

    const handleFloatChangeEvent = (event) => {
        const { name, value } = event.target;
        const floatValue = parseFloat(value)
        handleChange(name, floatValue);
    };

    const handleIntChangeEvent = (event) => {
        const { name, value } = event.target;
        const intValue = parseInt(value)
        handleChange(name, intValue);
    };

    const handleChange = (path, value) => {
        //console.log('path=', path, ', value=[', value, ']');
        const currItem = lodash.cloneDeep(selectedItem);
        lodash.set(currItem, path, value);
        setSelectedItem({ ...currItem });
    };

    const addCurrencyToLabel = (label, price) => {
        if (price) {
            return label + '(' + price.currency + ')';
        }
        return label;
    };

    // const renderBalance = (item) => {
    //     if (!item) return;
    //     let balance = item.totalcost - item.totalpaid
    //     const sanitized = Number.parseFloat(balance).toFixed(2)
    //     return (
    //         <span style={balance > 0 ? { color: 'red' } : { color: '#000' }}>${sanitized}</span>
    //     )
    // }

    const renderHeader = () => {
        return (
            <CardHeader
                className={classes.itemheader}
                avatar={getAvatar()}
                action={
                    <div>
                        <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === true}>
                            <Fab
                                disabled={item.deleted == 1}
                                size="small"
                                color="primary"
                                className={classes.fab}
                                onClick={onSetEditMode}
                            >
                                <EditIcon />
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Cancel" aria-label="Cancel Changes" hidden={!editMode}>
                            <Fab size="small" color="primary" className={classes.fab} onClick={onCancelChanges}>
                                <CancelIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === false}>
                            <Fab size="small" color="primary" className={classes.fab} onClick={saveChanges}>
                                <SaveIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                }
            />
        );
    };
    return (
        <Dialog
            onClose={(e, reason) => {
                if (reason === "backdropClick") {
                    return
                }
                onCloseInvoiceForm()
            }}
            maxWidth={false}
            //onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onBack
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{renderHeader()}</DialogTitle>
            <DialogContent dividers>
                <Box className={classes.taglist}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <InvoiceForm
                            item={selectedItem}
                            editMode={editMode}
                            onUpdate={onUpdate}
                            handleChangeEvent={handleChangeEvent}
                            handlePriceChangeEvent={handlePriceChangeEvent}
                            addCurrencyToLabel={addCurrencyToLabel}
                            handleFloatChangeEvent={handleFloatChangeEvent}
                            handleIntChangeEvent={handleIntChangeEvent}
                        />
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Toolbar name="actionbar">

                    <DownloadFile label="Download " request={prepareInvoiceDownloadRequest(selectedItem.id)} />

                    <Button
                        className={classes.buttonSubmit}
                        variant="contained"
                        onClick={() => {
                            if (!lodash.isEqual(item, selectedItem)) {
                                setShowAlertDialog(true)
                            } else {
                                onCloseInvoiceForm()
                            }
                        }}
                        color="primary">
                        <CheckIcon />
                    </Button>

                </Toolbar>

            </DialogActions>
            <AlertDialog
                open={showAlertDialog}
                title="Confirmation"
                content="You have unsaved changes. Are you sure you want to close invoice?"
                handleConfirm={(decision) => {
                    if (decision === true) {
                        onCloseInvoiceForm()
                    }
                }}
                handleClose={() => {
                    setShowAlertDialog(false)
                }}
            />
        </Dialog>
    )
}