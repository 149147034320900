export const userConstants = {
	REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

	LOGOUT: 'USERS_LOGOUT',
	LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
	LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
	LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

	USERINFO: 'USERS_USERINFO',
	USERINFO_REQUEST: 'USERS_USERINFO_REQUEST',
	USERINFO_SUCCESS: 'USERS_USERINFO_SUCCESS',
	USERINFO_FAILURE: 'USERS_USERINFO_FAILURE'
};
