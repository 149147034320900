//InventoryItemDocumentsView
import React, { useState } from 'react';
import { baseURL } from '../../helpers';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { Divider, makeStyles, Grid, Paper, Hidden } from '@material-ui/core';
import { PdfDocumentViewer } from '../PdfDocumentViewer';
import { authHeader } from '../../helpers';
import { DownloadFile } from '../DownloadFile';
import { UploadFile } from '../UploadFile';
import { DeleteFile } from '../DeleteFile';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { inventoryConstants } from '../../constants';
const useStyles = makeStyles((theme) => ({
	listcontainer: {
		height: '100%',
		width: '100%',
		maxWidth: 300,
		overflow: 'scroll',
		backgroundColor: theme.palette.background.paper,
		textAlign: 'left'
	},
	list: {
		width: '100%'
	},
	listitem: {
		paddingLeft: '2px',
		paddingRight: '2px',
		paddingTop: '2px',
		paddingBottom: '2px'
	},
	toolbar: {
		display: 'flex',
		flexGrow: 1,
		backgroundColor: '#9e9e9e27',
		textAlign: 'left',
		justifyContent: 'space-between'
	},
	container: {
		border: '1px',
		borderColor: 'solid black'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	midGridStyle: { minHeight: '100px', backgroundColor: 'transparent', marginBottom: '10px' },
	mobilefilelist: {
		width: '100%',
		// maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		margin: 'auto',
		maxHeight: 200,
		textAlign: 'left'
	},
	listSection: {
		backgroundColor: 'inherit'
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0
	},
	inventoryitemdocumentsview: {
		//width: '100% !important'
	}
}));

const prepareDocItems = (item) => {
	let docItems = [];
	if (item && item.docs) {
		const docObjects = Object.values(item.docs);
		if (docObjects) {
			for (const docItem of docObjects) {
				docItems.push({
					src: docItem.src,
					title: docItem.title,
					id: docItem.id
				});
			}
		}
	}
	return docItems;
};

const MakeshiftDrawer = ({ open, selectDocument, docItems }) => {
	const classes = useStyles();
	const [selectedId, setSelectedId] = React.useState('');

	function handleListItemClick(docItem) {
		setSelectedId(docItem.id);
		selectDocument(docItem);
	}
	//const docItems = prepareDocItems(item);
	return (
		<Slide name="makeshiftdrawerslide" direction="right" in={open} mountOnEnter unmountOnExit>
			<div className={classes.listcontainer}>
				<List component="nav" aria-label="main mailbox folders" className={classes.list}>
					{docItems.map((docItem, index) => (
						<ListItem
							button
							key={docItem.id}
							selected={selectedId === docItem.id}
							onClick={(event) => handleListItemClick(docItem)}
							className={classes.listitem}
						>
							<div style={{ display: 'flex' }}>
								<ArrowRightIcon />
								<p style={{ color: '#0000EE' }}>{docItem.src}</p>{' '}
							</div>
						</ListItem>
					))}
				</List>
				<Divider />
			</div>
		</Slide>
	);
};

const MobileFileListView = ({ open, selectDocument, docItems }) => {
	const classes = useStyles();
	const [selectedId, setSelectedId] = React.useState('');

	function handleListItemClick(docItem) {
		setSelectedId(docItem.id);
		selectDocument(docItem);
	}
	//const docItems = prepareDocItems(item);

	return (
		<List className={classes.mobilefilelist} subheader={<li />} name="filelist">
			<div className={classes.listcontainer}>
				<List component="nav" aria-label="main mailbox folders" className={classes.list}>
					{docItems.map((docItem, index) => (
						<ListItem
							button
							key={docItem.id}
							selected={selectedId === docItem.id}
							onClick={(event) => handleListItemClick(docItem)}
						>
							<ListItemIcon>
								<ArrowRightIcon />
							</ListItemIcon>
							<ListItemText primary={docItem.src} />
						</ListItem>
					))}
				</List>
				<Divider />
			</div>
		</List>
	);
};

export const InventoryItemDocumentsView = ({ item }) => {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(true);
	const [setIsDialogOpen] = useState(false);
	const [currentDocument, setCurrentDocument] = useState(null);
	const [docItems] = useState(prepareDocItems(item));
	const inventory = useSelector((state) => state.inventory);
	const searchquery = inventory ? inventory.searchquery : '';
	const dispatch = useDispatch();
	const isDisabled = item.deleted === 1 ? true : false;
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	const prepareDocRequest = (docItem) => {
		if (!docItem) return null;

		const docUrl = baseURL + '/doc/' + item.rid + '/' + docItem.src;
		//console.log('in prepareDocRequest, docItem=', docItem);
		const docRequest = {
			url: docUrl,
			httpHeaders: {
				...authHeader()
			},
			filename: docItem.src,
			action: 'GET'
		};
		//console.log('docRequest ', docRequest);
		return docRequest;
	};

	const docUploadUrl = "/doc/" + item.rid;

	const changeCurrentDocument = (document) => {
		if (document) {
			setCurrentDocument(document);
		}
	};

	// const handleDialogClose = () => {
	// 	setIsDialogOpen(false);
	// };

	const onChange = () => {
		//console.log('dispatch inventory refresh for search filter ', searchquery);
		dispatch({
			type: inventoryConstants.INVENTORY_SEARCH_REQUEST,
			payload: searchquery
		});

		//setDocItems(prepareDocItems(item));
	};

	return (
		<div name="inventoryitemdocumentsview" className={classes.inventoryitemdocumentsview}>
			<Grid container direction="row" className={classes.midGridStyle}>
				<Grid container item xs={12} className={classes.toolbar}>
					<Grid item xs={10}>
						<Button color="primary" onClick={toggle}>
							<MenuIcon />
						</Button>
						<UploadFile url={docUploadUrl} onChange={onChange} disabled={isDisabled} />
						<DeleteFile
							item={item}
							mediaType="doc"
							disabled={currentDocument == null || isDisabled}
							selectedFile={currentDocument}
							onChange={onChange}
							// style={{ color: currentDocument == null ? '' : 'red' }}
							iconOnly={true}
						/>
						<DownloadFile request={prepareDocRequest(currentDocument)} />
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center">
					<Hidden smDown>
						<Grid item xs={isOpen ? 3 : false} md={isOpen ? 3 : false} lg={isOpen ? 3 : false}>
							<MakeshiftDrawer
								open={isOpen}
								selectDocument={changeCurrentDocument}
								item={item}
								rel="noopener"
								docItems={docItems}
							/>
						</Grid>
					</Hidden>

					<Hidden mdUp>
						{isOpen && (
							<Grid>
								<MobileFileListView
									open={isOpen}
									selectDocument={changeCurrentDocument}
									item={item}
									docItems={docItems}
								/>
							</Grid>
						)}
					</Hidden>

					{/* <Grid item xs={isOpen ? 9 : 12} md={isOpen ? 9 : 12} lg={isOpen ? 9 : 12}> */}
					<Grid item xs={12} md={isOpen ? 9 : 12} lg={isOpen ? 9 : 12}>
						<Paper className={classes.container}>
							<PdfDocumentViewer
								request={prepareDocRequest(currentDocument)}
								shownav={true}
								title={item.title}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

//references:
//https://www.google.com/search?client=firefox-b-1-d&q=material-ui+grid+item+fixed+height
//https://stackblitz.com/edit/react-c2adpk?embed=1&file=MakeshiftDrawer.js
