import React, { useState } from 'react';
import { makeStyles, Box, Button, IconButton } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddBoxIcon from '@material-ui/icons/AddBox';

import ReactList from 'react-list';
const useStyles = makeStyles((theme) => ({
	taglist: {
		overflow: 'auto',
		maxHeight: '300px',
		overflowX: 'scroll',
		textAlign: 'left'
	},
	tagitem: {
		marginLeft: '5px'
	}
}));

export const InventoryItemTagsSetup = (props) => {
	const { onClose, onSaveTags, open, item, ...other } = props;
	const classes = useStyles();
	const [selectedTag, setSelectedTag] = useState('');
	const [foundTags, setFoundTags] = useState(item.tags);

	const handleOk = () => {
		onClose();
	};

	const handleAddTag = () => {
		if (!selectedTag) {
			return;
		}

		const tagIndex = item.tags.indexOf(selectedTag.toLowerCase());
		if (tagIndex < 0) {
			//console.log('adding tag ', selectedTag);
			let newTags = [...item.tags];
			newTags.push(selectedTag);
			onSaveTags(newTags);
			//reset the tags filter
			setSelectedTag('');
		} else {
			//console.log('tag already exists, found at ', tagIndex);
		}
	};

	const handleRemoveTag = (tagToRemove) => {
		//console.log('removing tag ', selectedTag);
		let newTags = item.tags.filter(function (value, index, arr) {
			return !(value.toLowerCase() === tagToRemove.toLowerCase());
		});

		if (newTags.length != item.tags.length) {
			onSaveTags(newTags);
		}
	};

	const filter = (e) => {
		const keyword = e.target.value;

		if (keyword !== '') {
			const results = item.tags.filter((selectedTag) => {
				return selectedTag.toLowerCase().startsWith(keyword.toLowerCase());
				// Use the toLowerCase() method to make it case-insensitive
			});
			setFoundTags(results);
		} else {
			setFoundTags(item.tags);
			// If the text field is empty, show all tags
		}

		setSelectedTag(keyword);
	};

	// const getWidth = (index) => 120;
	const renderItem = (index, key) => {
		return (
			<div key={foundTags[index]}>
				<Button
					key={foundTags[index]}
					//href="#text-buttons"
					color="primary"
					onClick={(e) => {
						//console.log('This button does not do anything!');
						e.preventDefault();
					}}
				>
					{foundTags[index]}
				</Button>
				<IconButton
					id={foundTags[index]}
					value={foundTags[index]}
					color="secondary"
					onClick={(e) => {
						// console.log('Delete action performed on tag', e.currentTarget.value);
						handleRemoveTag(e.currentTarget.value);
						e.preventDefault();
					}}
				>
					<CloseOutlinedIcon style={{ color: 'red' }} />
				</IconButton>
			</div>
		);
	};
	return (
		// <div style={{ overflow: 'auto', maxHeight: '300px', overflowX: 'scroll', textAlign: 'left' }}>
		<Dialog
			maxWidth={false}
			//onEntering={handleEntering}
			aria-labelledby="confirmation-dialog-title"
			open={open}
			{...other}
		>
			<DialogTitle id="confirmation-dialog-title">Tags Setup</DialogTitle>
			<DialogContent dividers>
				<Box className={classes.taglist}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<input
							type="search"
							value={selectedTag}
							onChange={filter}
							className="input"
							placeholder="Filter"
						/>
						<IconButton
							style={{ marginLeft: '2px', marginRight: '2px' }}
							variant="contained"
							onClick={handleAddTag}
						>
							<AddBoxIcon style={{ color: '#0081f2' }} />
						</IconButton>
					</div>

					<ReactList itemRenderer={renderItem} length={foundTags.length} type="variable" />
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOk} color="primary">
					Done
				</Button>
			</DialogActions>
		</Dialog>
	);
};
