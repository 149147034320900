import React, { useState } from 'react'
import { InvoiceTransactionsTable } from './InvoiceTransactionsTable'
import { InvoiceTransactionFormView } from './InvoiceTransactionFormView'
import { addInvoiceTransaction, updateInvoiceTransaction, deleteInvoiceTransaction } from '../../api'
import { NewInvoiceTransactionObject, SanitizeInvoiceTransactionObject } from '../../helpers';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../../helpers/alert';

export const InvoiceTransactions = (props) => {
    const { saleitem, onUpdate } = props
    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const { enqueueSnackbar } = useSnackbar();

    const selectTransaction = (id) => {
        //1. TODO: based on the provided id, select the transaction from saleitem.transactions via filter
        if (saleitem.transactions) {
            let res = saleitem.transactions.filter((transaction) => {
                if (transaction.id === id) {
                    return transaction
                }
            })
            if (res && res.length > 0) {
                setSelectedTransaction(res[0])
            }
        }
    }

    const addTransaction = () => {
        let newTransaction = NewInvoiceTransactionObject(saleitem)
        setSelectedTransaction(newTransaction)
    }

    const onClose = () => {
        setSelectedTransaction(null)
    }

    const onSubmitSuccess = (response) => {
        enqueueSnackbar('Transaction updated successfully', ALERT_VARIANT_SUCCESS);
        if (onUpdate) {
            onUpdate();
        }
        onClose();
    }

    const onSubmitFailure = (error) => {
        console.log('Error updating transaction!')
        enqueueSnackbar('Error updating transaction', ALERT_VARIANT_ERROR);
    }

    const onCreateTransaction = (transaction) => {
        //NOTE: we will sanitize the transaction to fix the data types
        const sanitizedTransaction = SanitizeInvoiceTransactionObject(transaction)
        if (sanitizedTransaction.isnew) {
            addInvoiceTransaction(sanitizedTransaction, onSubmitSuccess, onSubmitFailure)
        } else {
            console.log('error adding transaction')
        }
        onClose()
    }

    const onUpdateTransaction = (transaction) => {
        //NOTE: we will sanitize the transaction to fix the data types
        const sanitizedTransaction = SanitizeInvoiceTransactionObject(transaction)
        updateInvoiceTransaction(sanitizedTransaction, onSubmitSuccess, onSubmitFailure)
        onClose()
    }

    const onDeleteTransaction = (transaction) => {
        //NOTE: we will sanitize the transaction to fix the data types
        const sanitizedTransaction = SanitizeInvoiceTransactionObject(transaction)
        deleteInvoiceTransaction(sanitizedTransaction, onSubmitSuccess, onSubmitFailure)
        onClose()
    }


    //TODO: Add mechanism to undo selection i.e. on dialog closing via onClose or onSubmit callbacks
    return (
        <div>
            <InvoiceTransactionsTable
                saleitem={saleitem}
                selectTransaction={selectTransaction}
                addTransaction={addTransaction}
            />
            {selectedTransaction &&
                <InvoiceTransactionFormView
                    onClose={onClose}
                    onCreate={onCreateTransaction}
                    onUpdate={onUpdateTransaction}
                    onDelete={onDeleteTransaction}
                    open={!!selectedTransaction}
                    transaction={selectedTransaction}
                />
            }
            {/* 
                1. include
            
            */}
        </div>
    )
}