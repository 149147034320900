import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { SaleItemTable } from '../components/sales';
import Loader from '../components/Loader';
import { SalesHeader } from '../components/sales/SalesHeader';
import { GQL_GET_SALEITEMS } from '../gqlqueries';
import { defaultSearchFilters, graphqlPollInterval } from '../helpers';
import { useDispatch } from 'react-redux';
import { salesConstants } from '../constants';
import { InvoiceFormView } from '../components/sales/InvoiceFormView';
import { ApiCreateInvoice, ApiUpdateInvoice, ApiGetInvoice } from '../api';
import { SanitizeInvoiceObject } from '../helpers';
import { useSnackbar } from 'notistack';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../helpers/alert';
const useStyles = makeStyles((theme) => ({
	salescontainer: {
		width: '100%',
		margin: '0px'
	},
	box: {
		width: '100%',
		padding: theme.spacing(1),
		margin: '2px'
	},
	salesroot: {
		backgroundColor: '#fff'
	}
}));

const SalesPage = (props) => {
	const sales = useSelector((state) => state.sales);

	const [sortOption, setSortOption] = useState('Featured');
	//inventoryView has two possible values: 'inventory' and 'itemdetails'
	//const [ inventoryView, setInventoryView ] = useState('inventory');

	//possible filter values: featured, purchasepricelow, purchasepricehigh, purchasedateold, purchasedatanew
	const sortOptionChanged = (thechange) => {
		setSortOption(thechange.target.value);
	};

	const dispatch = useDispatch();
	const classes = useStyles();
	const search = sales ? sales.searchquery : '';
	const filters = sales ? sales.searchfilters : defaultSearchFilters;
	const selectedInvoiceItem = sales ? sales.selectedInvoice : null;
	const { enqueueSnackbar } = useSnackbar();

	const { loading, error, data, refetch } = useQuery(GQL_GET_SALEITEMS, {
		variables: { search: search, filters: JSON.stringify(filters) },
		pollInterval: graphqlPollInterval,
		errorPolicy: 'all'
	});

	if (!selectedInvoiceItem && !error) {
		refetch().catch((e) => null);
	}

	const getTableView = () => {
		return (
			<div name="getinventoryview" className={classes.salescontainer}>
				{loading && <Loader />}
				{error && <p style={{ color: 'red', fontWeight: '600' }}>Error loading data from server...</p>}
				{data &&
					<div>
						<SaleItemTable saleitems={data.saleitems} />
					</div>
				}
			</div>
		);
	};


	const performTagSearch = (search) => {
		if (!search) {
			search = '';
		}
		dispatch({
			type: salesConstants.SALES_SEARCH_REQUEST,
			payload: { search: search, filters: defaultSearchFilters }
		});
	};

	const onRefreshData = () => {
		performTagSearch('');
	};

	const onCloseInvoiceFormButtonClicked = () => {
		setSelectedInvoice(null)
	};

	const setSelectedInvoice = (invoice) => {
		console.log("the selected invoice is ", invoice)
		dispatch({
			type: salesConstants.SALES_ITEM_SELECT_REQUEST,
			payload: invoice
		});
	}

	const onInvoiceFormSubmissionSuccess = (response) => {
		enqueueSnackbar('Invoice saved successfully', ALERT_VARIANT_SUCCESS);
		onCloseInvoiceFormButtonClicked()
	}

	const onInvoiceFormSubmissionFailure = (response) => {
		enqueueSnackbar('Error saving invoice', ALERT_VARIANT_ERROR);
	}

	const onSubmitInvoiceForm = (modifiedItem) => {
		const sanitizedInvoice = SanitizeInvoiceObject(modifiedItem)
		//1. Submit the invoice form through the api
		if (sanitizedInvoice.isnew === true) {
			ApiCreateInvoice(sanitizedInvoice, onInvoiceFormSubmissionSuccess, onInvoiceFormSubmissionFailure);
		} else {
			ApiUpdateInvoice(sanitizedInvoice, onInvoiceFormSubmissionSuccess, onInvoiceFormSubmissionFailure);
		}
	}

	const onUpdate = () => {
		console.log('refreshing invoice from server')
		if (selectedInvoiceItem) {
			ApiGetInvoice(selectedInvoiceItem.id, setSelectedInvoice)
		}
	}

	const showSelectedInvoiceForm = () => {
		if (!selectedInvoiceItem) return
		console.log('In SalesPage, selectedInvoiceItem is ', selectedInvoiceItem)
		return (
			<InvoiceFormView
				item={selectedInvoiceItem}
				open={!!selectedInvoiceItem}
				onUpdate={onUpdate}
				onSubmitInvoiceForm={onSubmitInvoiceForm}
				onCloseInvoiceForm={onCloseInvoiceFormButtonClicked}
			/>
		)
	}

	return (
		<div name="salespage" className={classes.salesroot}>
			<SalesHeader
				data={data ? data.saleitems : []}
				sortOptionChanged={sortOptionChanged}
				selectedSortOption={sortOption}
				onRefreshData={onRefreshData}
			/>
			{getTableView()}
			{showSelectedInvoiceForm()}
		</div>
	);
};

export { SalesPage };

//NOTES:
//1/22/2021:
// - Removing the right pane for now
// - Can be reintroduced to host optional widgets
// - When reintroducing the right pane, the breakpoints on middle pane need to be set accordingly
