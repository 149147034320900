import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import lodash from 'lodash';
import { inventoryConstants } from '../../constants';
import { useDispatch } from 'react-redux';
import './InventoryItemTable.css';
import { getDateInFormatOne } from '../../helpers';
import { baseURL } from '../../helpers';
import { DownloadFile } from '../DownloadFile';
import { authHeader } from '../../helpers';
import history from '../../helpers/history';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getUserEID } from '../../helpers';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell);

const columns = [
	{
		field: 'title',
		numeric: false,
		label: 'Title',
		disablePadding: false,
		align: 'left',

		getter: (value, actionHandler, classes) => {
			return (
				<div className={classes.titleColumn}>
					<Link
						aria-label="Title"
						onClick={() => {
							actionHandler(value);
						}}
					>
						{value.title}
					</Link>
				</div>
			);
		}
	},
	{ field: 'eid', numeric: false, label: 'Business', disablePadding: false, align: 'left', minWidth: 70 },
	{
		field: 'details.vin',
		numeric: false,
		label: 'VIN',
		disablePadding: false,
		align: 'left',
		minWidth: 70,
		getter: (value, actionHandler) => {
			return (
				<div>
					<Link
						aria-label="VIN"
						onClick={() => {
							actionHandler(value);
						}}
					>
						{value.details.vin}
					</Link>
				</div>
			);
		}
	},
	{ field: 'details.year', numeric: true, label: 'Year', disablePadding: false, align: 'center', minWidth: 130 },
	{
		field: 'details.exteriorcolor',
		numeric: false,
		label: 'Color',
		disablePadding: false,
		align: 'left',
		minWidth: 130
	},
	{
		field: 'details.trim',
		label: 'Trim',
		disablePadding: false,
		align: 'left',
		minWidth: 90,
		numeric: false,
		getter: (value, field, defaultValue) => {
			return lodash.get(value, 'details.trim', defaultValue);
		}
	},
	{
		field: 'details.purchaseprice.value',
		label: 'PurchasePrice($)',
		disablePadding: false,
		sortable: true,
		align: 'center',
		minWidth: 50,
		numeric: true,
		format: (value) => {
			//console.log(value);
		},
		getter: (value, field, defaultValue) => {
			return value.details.purchaseprice.value;
		}
	},
	{
		field: 'details.purchasedate',
		label: 'PurchaseDate',
		disablePadding: false,
		align: 'left',
		minWidth: 90,
		getter: (value, field, defaultValue) => {
			//return lodash.get(value, field, defaultValue);
			const v = lodash.get(value, field, defaultValue);
			if (!v) {
				return '-';
			} else {
				return getDateInFormatOne(lodash.get(value, field, defaultValue));
			}
		}
	},
	{
		field: 'details.saledate',
		label: 'SaleDate',
		disablePadding: false,
		sortable: true,
		align: 'center',
		minWidth: 50,
		format: (value) => value.toLocaleString('en-US'),
		numeric: true,
		getter: (value, field, defaultValue) => {
			//return lodash.get(value, field, defaultValue);
			const v = lodash.get(value, field, defaultValue);
			if (!v) {
				return '-';
			} else {
				return getDateInFormatOne(lodash.get(value, field, defaultValue));
			}
		}
	},
	{
		field: 'modifiedtime',
		label: 'ModifiedDate',
		disablePadding: false,
		sortable: true,
		align: 'center',
		minWidth: 50,
		format: (value) => value.toLocaleString('en-US'),
		numeric: false,
		getter: (value, field, defaultValue) => {
			const v = lodash.get(value, field, defaultValue);
			if (!v) {
				return '-';
			} else {
				return getDateInFormatOne(lodash.get(value, field, defaultValue));
			}
		}
	}
];

function descendingComparator(a, b, orderBy) {
	const av = lodash.get(a, orderBy);
	const bv = lodash.get(b, orderBy);
	//console.log('av: ', av, ', bv: ', bv, ', orderBy: ', orderBy);
	if (!av || !bv) return 0;
	return bv.localeCompare(av, undefined, {
		numeric: true,
		sensitivity: 'base'
	});
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applyShowExternalFilter(showExternal, array) {
	console.log('in applyShowExternalFilter')
	if (showExternal) {
		return array
	}
	const myEID = getUserEID();
	const filtered = array.filter((el) => {
		if (el.eid === myEID) {
			return el
		}
	})

	return filtered
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		return comparator(a[0], b[0]);
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow className={classes.tableheaderrow}>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all' }}
					/>
				</TableCell>
				{columns.map((column) => (
					<TableCell
						key={column.field}
						align={column.align}
						padding={column.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === column.field ? order : false}
						style={{ minWidth: column.minWidth }}
					>
						<TableSortLabel
							active={orderBy === column.field}
							direction={orderBy === column.field ? order : 'asc'}
							onClick={createSortHandler(column.field)}
						>
							{column.label}
							{orderBy === column.field ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark
			},
	title: {
		flex: '1 1 100%'
	}
}));

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected, downloadRequest, handleChange, showExternal } = props;
	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0
			})}
		>
			<FormControlLabel
				value="end"
				control={
					<Switch
						color="primary"
						checked={showExternal}
						onChange={handleChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				}
				label="External"
				labelPlacement="end"
			/>

			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
					Inventory
				</Typography>
			)}

			<DownloadFile request={downloadRequest} />
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	downloadRequest: PropTypes.object,
	handleChange: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	deleteditem: {
		backgroundColor: '#fb020230'
	},
	externalitem: {
		backgroundColor: '#ffeb3b1f'
	},
	tableheaderrow: {
		backgroundColor: '#f3f3f3'
	},
	titleColumn: {
		minWidth: 100,
		[theme.breakpoints.up('sm')]: {
			minWidth: 180
		},
		textDecoration: 'underline'
	}
}));

export const InventoryItemTable = ({ items, search, filters }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('modifiedtime');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense] = React.useState(true);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const [showExternal, setShowExternal] = useState(true);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	items = showExternal === false ? applyShowExternalFilter(showExternal, items) : items;
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = items.map((n) => n.rid);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const onSetSelectedItem = (item) => {
		dispatch({
			type: inventoryConstants.INVENTORY_ITEM_SELECT_REQUEST,
			payload: item
		});
	};

	const redirectToResearch = (item) => {
		history.push('/research', { vin: item.details.vin });
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

	const prepareDownloadRequest = () => {
		const apiUrl = baseURL + '/items/pdfsimple';
		let data = {
			search: search,
			filters: filters,
			itemList: selected
		};
		//TODO: add a date and time (upto seconds) in the filename
		const request = {
			url: apiUrl,
			httpHeaders: {
				...authHeader()
			},
			filename: 'myinventory.pdf',
			data: data,
			action: 'POST'
		};

		//console.log(request);
		return request;
	};

	const getColumnValue = (item, column) => {
		//details.vin
		let value;
		if (column.getter) {
			//debugger;
			switch (column.field) {
				case 'moreinfo':
					//value = column.getter(item, myEID != item.eid, onShowInVoice);
					break;
				case 'title':
					value = column.getter(item, onSetSelectedItem, classes);
					break;

				case 'details.vin':
					value = column.getter(item, redirectToResearch);
					break;
				default:
					value = column.getter(item, column.field, '');
					break;
			}
		} else {
			value = lodash.get(item, column.field, '');
		}

		return value;
	};

	const handleShowExternalChange = () => {
		//console.log('switching handleShowExternalChange')
		setShowExternal(!showExternal)
	}
	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar numSelected={selected.length} downloadRequest={prepareDownloadRequest()} handleChange={handleShowExternalChange} showExternal={showExternal} />
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={items.length}
						/>
						<TableBody>
							{stableSort(items, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => {
									const isItemSelected = isSelected(item.rid);
									const labelId = `enhanced-table-checkbox-${index}`;
									const extData = getUserEID() !== item.eid
									return (
										<TableRow
											hover
											//onClick={(event) => handleClick(event, item.rid)}
											role="row"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={item.rid}
											selected={isItemSelected}
											className={item.deleted == 1 ? classes.deleteditem : '', extData ? classes.externalitem : ''}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isItemSelected}
													inputProps={{ 'aria-labelledby': labelId }}
													onChange={(event) => handleClick(event, item.rid)}
												/>
											</TableCell>
											{columns.map((column) => {
												//console.log('eid match, myEID=', myEID, ' item.eid=', item.eid);
												//NOTE: If a getter was provided, i.e. for complex fields, then use that, else do direct access
												const value = getColumnValue(item, column);
												return (
													<StyledTableCell key={column.field} align={column.align}>
														{column.format && typeof value === 'number' ? (
															column.format(value)
														) : (
															value
														)}
													</StyledTableCell>
												);
											})}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 100]}
					component="div"
					count={items.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			{/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
		</div>
	);
};
