import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { inventory } from './inventory.reducer';
import { sales } from './sales.reducer';
import { main } from './main.reducer';
const rootReducer = combineReducers({
	authentication,
	alert,
	inventory,
	sales,
	main
});

export default rootReducer;
