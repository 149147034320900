import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { inventoryConstants, mainConstants } from '../constants';
import history from '../helpers/history';
import { processFrontendLogout } from '../helpers/util';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Business from '@material-ui/icons/Business';
import MoreIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import logo from '../resources/logo512.png';
import { SearchBar } from './Searchbar';
const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	selectedMobileMenuItem: {},
	title: {
		color: '#000',
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block'
		}
	},
	logo: {
		color: '#fff',
		marginRight: theme.spacing(1),
		width: theme.spacing(3.5),
		height: theme.spacing(2)
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		}
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	default: {},
	appbar: {
		backgroundColor: '#f9f9f9',
		color: '#000'
	},
	appbarbutton: {
		fontSize: '1.2em'
	}
}));

const PrimarySearchAppBar = (props) => {
	const [selectedPage, setSelectedPage] = useState('');
	const dispatch = useDispatch();
	//hidden={user != null}
	const [finalQuery, setQuery] = useState('');
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const { user } = props;
	if (!user) {
		return <Fragment />;
	}

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const doLogout = () => {
		processFrontendLogout('user');
		//regardless, of success, we will get to root path
		history.push('/');
	};

	// const doLogin = () => {
	// 	history.push('/login');
	// };

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const onSearchQueryChange = (event) => {
		const value = event.target.value;
		setQuery(value);
	};

	const onSearchQuerySubmit = (searchFilters) => {
		const searchQuery = finalQuery;
		//NOTE: Skipping the redux update for now, since the Header will always be present and will hold the latest search filter
		//inventoryActions.searchinventory(finalQuery);
		dispatch({
			type: inventoryConstants.INVENTORY_SEARCH_REQUEST,
			payload: { search: searchQuery, filters: searchFilters }
		});
		setSelectedPage('inventory');
		history.push('/inventory');
		//setQuery('');
	};

	const onShowProfileDialog = () => {
		dispatch({
			type: mainConstants.MAIN_SET_PROFILE_DISPLAY,
			payload: true
		});
	}

	const menuId = 'primary-search-account-menu';
	const renderUserMenu = (props) => {
		//const { user } = props;
		return (
			<Menu
				style={{ color: '#9e9e9e' }}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={menuId}
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMenuOpen}
				onClose={handleMenuClose}
			>
				{user && (
					<div>
						<MenuItem
							onClick={handleMenuClose}
							hidden={user === null}
							className={selectedPage === 'myaccount' ? classes.selectedMobileMenuItem : classes.default}
						>
							<div
								aria-label="My Account"
								onClick={() => {
									// setSelectedPage('myaccount');
									// history.push('/myaccount');
									//NOTE: We will show the profile dialog
									onShowProfileDialog();
								}}
							>
								My Account
							</div>
						</MenuItem>
						<MenuItem onClick={handleMenuClose} hidden={user == null}>
							<div aria-label="Research" onClick={doLogout}>
								Log off
							</div>
						</MenuItem>
					</div>
				)}
				<MenuItem onClick={handleMenuClose} hidden={user != null}>
					<div
						aria-label="Login"
						onClick={() => {
							history.push('/login');
						}}
					>
						Login
					</div>
				</MenuItem>
			</Menu>
		);
	};

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<div>
				{/* <MenuItem
					className={selectedPage === 'portal' ? classes.selectedMobileMenuItem : classes.default}
					onClick={() => {
						setSelectedPage('portal');
						history.push('/portal');
						handleMobileMenuClose();
					}}
					onClose={handleMobileMenuClose}
				>
					<IconButton color="inherit" aria-label="My Portal" component="span">
						<DesktopMacIcon />
					</IconButton>
					My Portal
				</MenuItem> */}
				<MenuItem
					className={selectedPage === 'inventory' ? classes.selectedMobileMenuItem : classes.default}
					onClick={() => {
						setSelectedPage('inventory');
						history.push('/inventory');
						handleMobileMenuClose();
					}}
					onClose={handleMobileMenuClose}
				>
					<IconButton color="inherit" aria-label="Inventory" component="span">
						<LibraryBooksIcon />
					</IconButton>
					Inventory
				</MenuItem>
				<MenuItem
					className={selectedPage === 'sales' ? classes.selectedMobileMenuItem : classes.default}
					onClick={() => {
						setSelectedPage('sales');
						history.push('/sales');
						handleMobileMenuClose();
					}}
					onClose={handleMobileMenuClose}
				>
					<IconButton color="inherit" aria-label="Sales" component="span">
						<Business />
					</IconButton>
					Sales
				</MenuItem>
				<MenuItem
					className={selectedPage === 'research' ? classes.selectedMobileMenuItem : classes.default}
					onClick={() => {
						setSelectedPage('research');
						history.push('/research');
						handleMobileMenuClose();
					}}
					onClose={handleMobileMenuClose}
				>
					<IconButton color="inherit" aria-label="Research" component="span">
						<ListAltIcon />
					</IconButton>
					Research
				</MenuItem>

				<Divider orientation="vertical" flexItem />

				{/* <MenuItem onClose={handleMobileMenuClose} disabled>
					<IconButton aria-label="show 4 new mails" color="inherit">
						<Badge badgeContent={4} color="secondary">
							<MailIcon />
						</Badge>
					</IconButton>
					Messages
				</MenuItem>
				
				<MenuItem onClose={handleMobileMenuClose} disabled>
					<IconButton aria-label="show 11 new notifications" color="inherit">
						<Badge badgeContent={11} color="secondary">
							<NotificationsIcon />
						</Badge>
					</IconButton>
					Notifications
				</MenuItem> */}
				<MenuItem onClick={handleProfileMenuOpen} onClose={handleMobileMenuClose}>
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
					>
						<AccountCircle />
					</IconButton>
					Profile
				</MenuItem>
			</div>
		</Menu>
	);

	return (
		<div className={classes.grow} name="header">
			<AppBar position="static" className={classes.appbar}>
				<Toolbar name="searchtoolbar" className={classes.toolbar}>
					<div
						style={{ display: 'flex', flexDirection: 'row' }}
						onClick={() => {
							setSelectedPage('homepage');
							history.push('/');
						}}
					>
						<Avatar variant="rounded" className={classes.logo} alt="eMotoron Logo" src={logo} />
						<Typography className={classes.title} variant="h6" noWrap>
							eMotoron
						</Typography>
					</div>
					<SearchBar
						onSubmitCallback={onSearchQuerySubmit}
						onSearchQueryChangeCallback={onSearchQueryChange}
					/>
					<div className={classes.sectionDesktop}>
						{/* <Button
							color="inherit"
							variant={selectedPage === 'portal' ? 'outlined' : 'text'}
							aria-label="Inventory"
							component="span"
							onClick={() => {
								setSelectedPage('portal');
								history.push('/portal');
							}}
						>
							MyPortal
						</Button> */}
						<Button
							className={classes.appbarbutton}
							color="inherit"
							variant={selectedPage === 'inventory' ? 'outlined' : 'text'}
							aria-label="Inventory"
							component="span"
							size="large"
							onClick={() => {
								setSelectedPage('inventory');
								history.push('/inventory');
							}}
						>
							Inventory
						</Button>
						<Button
							className={classes.appbarbutton}
							color="inherit"
							variant={selectedPage === 'sales' ? 'outlined' : 'text'}
							aria-label="Sales"
							component="span"
							size="large"
							onClick={() => {
								setSelectedPage('sales');
								history.push('/sales');
							}}
						>
							Sales
						</Button>
						<Button
							color="inherit"
							variant={selectedPage === 'research' ? 'outlined' : 'text'}
							aria-label="Research"
							component="span"
							className={classes.appbarbutton}
							onClick={() => {
								setSelectedPage('research');
								history.push('/research');
							}}
						>
							Research
						</Button>
						<Divider orientation="vertical" flexItem />
						{/* <IconButton aria-label="show 17 new notifications" color="inherit">
							<Badge badgeContent={17} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton> */}
						<div>
							<Button
								onClick={handleProfileMenuOpen}
								color="inherit"
								startIcon={<AccountCircle style={{ color: '#0081f2' }} />}
							>
								<span style={{ color: '#0081f2', fontWeight: 'bold', textDecoration: 'underline' }}>
									{user.username}
								</span>
							</Button>
						</div>
					</div>
					<div className={classes.sectionMobile}>
						{user && (
							<IconButton
								aria-label="show more"
								aria-controls={mobileMenuId}
								aria-haspopup="true"
								onClick={handleMobileMenuOpen}
								color="inherit"
							>
								<MoreIcon />
							</IconButton>
						)}
						{!user && (
							<div>
								<IconButton
									aria-label="login"
									color="inherit"
									onClick={() => {
										history.push('/login');
									}}
								>
									<AccountCircle />
								</IconButton>
							</div>
						)}
					</div>
				</Toolbar>
			</AppBar>
			{user && renderMobileMenu}
			{renderUserMenu(props)}
		</div>
	);
};

export { PrimarySearchAppBar as Header };

//REFERENCES:
//https://material-ui.com/components/menus/
