import React, { useState, useEffect } from 'react'
import { InvoiceTransactionForm } from './InvoiceTransactionForm'
import { makeStyles, Fab, Tooltip, Grid, Box, Button, CardHeader } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import lodash from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AlertDialog } from '../AlertDialog';
import Toolbar from '@mui/material/Toolbar';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    transactions: {
        marginLeft: '5px'
    },
    buttonSubmit: {
        background: 'green'
    },
    buttonCancel: {
        background: 'gray'
    },
    fab: {
        marginLeft: theme.spacing(1),
    }
}));

export const InvoiceTransactionFormView = (props) => {
    const { transaction, open, onCreate, onUpdate, onDelete, onClose, ...other } = props
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState({ ...transaction });
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [editMode, setEditMode] = useState(
        transaction.isnew ? (transaction.isnew === true ? true : false) : false
    );

    const getAvatar = () => {
        return <h4>Transaction</h4>;
    };

    const onSetEditMode = (event) => {
        setEditMode(true);
        event.stopPropagation();
    };

    const onSaveChanges = (event) => {
        event.stopPropagation();
        setEditMode(false);
        if (transaction.isnew) {
            onCreate(selectedItem)
        } else {
            onUpdate(selectedItem)
        }
    };

    const onDeleteTransaction = (event) => {
        console.log('deleting the transaction')
        event.stopPropagation();
        onDelete(selectedItem)
        setEditMode(false);
    };

    const onCancelChanges = (event) => {
        event.stopPropagation();
        setSelectedItem({ ...transaction });
        setEditMode(false);
    };

    useEffect(
        () => { },
        [transaction]
    );

    const handleChangeEvent = (event) => {
        const { name, value } = event.target;
        handleChange(name, value);
    };

    const handleFloatChangeEvent = (event) => {
        const { name, value } = event.target;
        const floatValue = parseFloat(value)
        handleChange(name, floatValue);
    };

    const handleChange = (path, value) => {
        //console.log('path=', path, ', value=[', value, ']');
        const currItem = lodash.cloneDeep(selectedItem);
        lodash.set(currItem, path, value);
        setSelectedItem({ ...currItem });
    };

    // const addCurrencyToLabel = (label, price) => {
    //     if (price) {
    //         return label + '(' + price.currency + ')';
    //     }
    //     return label;
    // };

    const renderHeader = () => {
        return (
            <CardHeader
                className={classes.itemheader}
                avatar={getAvatar()}
                action={
                    <div>
                        <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === true}>
                            <Fab
                                disabled={transaction.deleted == 1}
                                size="small"
                                color="secondary"
                                className={classes.fab}
                                style={{ backgroundColor: '#f50057' }}
                                onClick={onDeleteTransaction}
                            >
                                <DeleteForeverIcon />
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === true}>
                            <Fab
                                disabled={transaction.deleted == 1}
                                size="small"
                                color="primary"
                                className={classes.fab}
                                style={{ backgroundColor: '#007bff' }}
                                onClick={onSetEditMode}
                            >
                                <EditIcon />
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Cancel" aria-label="Cancel Changes" hidden={!editMode}>
                            <Fab size="small" color="primary" className={classes.fab} style={{ backgroundColor: 'gray' }} onClick={onCancelChanges}>
                                <CancelIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === false}>
                            <Fab size="small" color="primary" className={classes.fab} style={{ backgroundColor: '#007bff' }} onClick={onSaveChanges}>
                                <SaveIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                }
            />
        );
    };
    return (
        <Dialog
            onClose={(e, reason) => {
                if (reason === "backdropClick") {
                    return
                }
                onClose()
            }}
            maxWidth={false}
            //onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onBack
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{renderHeader()}</DialogTitle>
            <DialogContent dividers>
                <Box className={classes.taglist}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <InvoiceTransactionForm
                            transaction={selectedItem}
                            editMode={editMode}
                            handleChangeEvent={handleChangeEvent}
                            handleFloatChangeEvent={handleFloatChangeEvent}
                        />
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button
                        className={classes.buttonSubmit}
                        variant="contained"
                        onClick={() => {
                            if (!lodash.isEqual(transaction, selectedItem)) {
                                setShowAlertDialog(true)
                            } else {
                                onClose()
                            }
                        }}
                        color="primary">
                        Done
                    </Button>
                </Toolbar>

            </DialogActions>
            <AlertDialog
                open={showAlertDialog}
                title="Confirmation"
                content="You have unsaved changes. Are you sure you want to close transaction?"
                handleConfirm={(decision) => {
                    if (decision === true) {
                        onClose()
                    }
                }}
                handleClose={() => {
                    setShowAlertDialog(false)
                }}
            />
        </Dialog>
    )
}