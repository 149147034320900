import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { mainConstants } from '../../constants'
import { Box, Button, CardHeader } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { ALERT_VARIANT_SUCCESS, ALERT_VARIANT_ERROR } from '../../helpers/alert';
import { Toolbar, Tabs, Tab, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { UserInfoSummary } from './UserInfoSummary';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded';
import { UserProfileView } from './UserProfileView';
import { BusinessProfileView } from './BusinessProfileView';
import { ApiGetUserInfo, ApiSaveUserProfile, ApiGetBusinessInfo, ApiSaveBusinessProfile } from '../../api/profile'
const useStyles = makeStyles((theme) => ({
    invoiceform: {
        overflow: 'auto',
        maxHeight: '300px',
        overflowX: 'scroll',
        textAlign: 'left'
    },
    transactions: {
        marginLeft: '5px'
    },
    buttonSubmit: {
        background: 'green'
    },
    buttonCancel: {
        background: 'gray'
    },
    fab: {
        backgroundColor: '#0081f2'
    }
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export const Profile = (props) => {
    const { open, onCloseProfileDialog, user, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(0)
    const [userprofile, setUserprofile] = useState(null);
    const [businessinfo, setBusinessInfo] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const getAvatar = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>{user.name}</h3>
            </div >
        );
    };

    const saveUserProfileChanges = (updatedprofile) => {
        let request = {
            username: user.username,
            userprofile: updatedprofile,
        }
        ApiSaveUserProfile(request,
            () => {
                //TODO: add a notification here
                setUserprofile({ ...updatedprofile })
                enqueueSnackbar('Successfully updated User Profile', ALERT_VARIANT_SUCCESS);
            },
            (error) => {
                //TODO: add a notification here
                console.log(error)
                enqueueSnackbar('Error updating User Profile', ALERT_VARIANT_ERROR);
            }
        )
    };

    const saveBusinessProfileChanges = (updatedBusinessProfile) => {
        let request = {
            eid: user.eid,
            businessprofile: updatedBusinessProfile,
        }
        ApiSaveBusinessProfile(request,
            () => {
                //TODO: add a notification here
                let updatedBusinessInfo = { ...businessinfo }
                updatedBusinessInfo.businessprofile = { ...updatedBusinessProfile }
                setBusinessInfo({ ...updatedBusinessInfo })
                enqueueSnackbar('Successfully updated Business Profile', ALERT_VARIANT_SUCCESS);
            },
            (error) => {
                //TODO: add a notification here
                enqueueSnackbar('Error updating Business Profile', ALERT_VARIANT_ERROR);
                console.log(error)
            }
        )
    }

    const handleTabChange = (event, newValue) => {
        if (newValue === tabValue) {
            setTabValue(99999);
        } else {
            setTabValue(newValue);
        }
    };

    const renderHeader = () => {
        return (
            <CardHeader
                className={classes.itemheader}
                avatar={getAvatar()}
                title={
                    <div>Profile</div>
                }
            />
        );
    };

    const onCloseProfileDialogEvent = () => {
        dispatch({
            type: mainConstants.MAIN_SET_PROFILE_DISPLAY,
            payload: false
        });

    }

    const renderContent = () => {
        return (
            <Box>
                {userprofile &&
                    <UserInfoSummary userprofile={userprofile}></UserInfoSummary>
                }
                <Divider />
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="Profile Options">
                    <Tab icon={<AccountCircleRoundedIcon />} label="My Profile" />
                    {user.accounttype === 'admin' &&
                        <Tab icon={<GroupWorkRoundedIcon />} label="Business" />
                    }
                </Tabs>
                <Divider />
                <TabPanel value={tabValue} index={0} style={{ overflow: 'auto', padding: '2px' }} name="tabpanelforimages">
                    <div className={classes.media}>
                        <UserProfileView userprofile={userprofile} saveUserProfileChanges={saveUserProfileChanges}></UserProfileView>
                    </div>
                </TabPanel>
                {user.accounttype === 'admin' && businessinfo != null &&
                    <TabPanel value={tabValue} index={1} style={{ overflow: 'auto', padding: '2px' }} name="tabpanelforpdf">
                        <div className={classes.media}>
                            <BusinessProfileView user={user} businessinfo={businessinfo} saveBusinessProfileChanges={saveBusinessProfileChanges}></BusinessProfileView>
                        </div>
                    </TabPanel>
                }
            </Box>
        )
    }

    useEffect(
        () => {
            if (!userprofile) {
                const request = {
                    "username": user.username
                }
                ApiGetUserInfo(request,
                    (data) => {
                        setUserprofile(data)
                    },
                    (err) => {
                        console.log(err)
                    }
                )
            }

            if (user.accounttype === 'admin' && !businessinfo) {
                ApiGetBusinessInfo(user.eid,
                    (data) => {
                        setBusinessInfo(data)
                    },
                    (err) => {
                        console.log(err)
                    }
                )
            }
        },
        [userprofile]
    );

    return (
        <Dialog
            onClose={(e, reason) => {
                if (reason === "backdropClick") {
                    return
                }
                onCloseProfileDialogEvent()
            }}
            maxWidth={false}
            //onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onBack
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{renderHeader()}</DialogTitle>
            <DialogContent dividers>
                {renderContent()}
            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button
                        className={classes.buttonSubmit}
                        variant="contained"
                        color="primary"
                        onClick={onCloseProfileDialogEvent}
                    >
                        Done
                    </Button>
                </Toolbar>
            </DialogActions>
        </Dialog>
    )
}