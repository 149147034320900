import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export const AlertDialog = (props) => {
	const { open, title, content, handleConfirm, handleClose } = props;
	const handleAgree = () => {
		handleConfirm(true);
		handleClose();
	};
	const handleDisagree = () => {
		handleConfirm(false);
		handleClose();
	};
	//console.log('in AlertDialog open=', open);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleAgree} color="primary">
						Yes
					</Button>
					<Button onClick={handleDisagree} color="primary" autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
AlertDialog.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired
};
