import React, { useState, useEffect } from 'react'
import { Tooltip, Fab, Box, Button } from '@mui/material'
import { BusinessProfileForm } from './BusinessProfileForm';
import { makeStyles } from '@mui/styles'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useSnackbar } from 'notistack';
import lodash from 'lodash';
import { ApiGetBusinessLogo } from '../../api';

const useStyles = makeStyles((theme) => ({
    passwordChange: {
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export const BusinessProfileView = (props) => {
    const classes = useStyles();
    const { businessinfo, saveBusinessProfileChanges, user } = props;
    const [logoImage, setLogoImage] = useState(null)

    const [editMode, setEditMode] = useState(false)
    //const { enqueueSnackbar } = useSnackbar();
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState({ ...businessinfo.businessprofile });

    const handleChangeEvent = (event) => {
        const { name, value } = event.target;
        //console.log(name, value);
        handleChange(name, value);
    };
    const handleChange = (path, value) => {
        const currItem = lodash.cloneDeep(currentBusinessProfile);
        lodash.set(currItem, path, value);
        //console.log(currItem)
        setCurrentBusinessProfile({ ...currItem });
    };

    const onCancelChanges = (event) => {
        event.stopPropagation();
        setCurrentBusinessProfile({ ...businessinfo.businessprofile })
        setEditMode(false);
    };

    const onSaveChanges = (event) => {
        event.stopPropagation();
        saveBusinessProfileChanges(currentBusinessProfile)
        setEditMode(false)
    }

    const onSetEditMode = (mode) => {
        setEditMode(mode);
    };

    useEffect(
        () => {
            if (!logoImage) {
                //console.log('pulling business logo image from server...')
                ApiGetBusinessLogo(user.eid,
                    (data) => {
                        //console.log('received data from server, data=', data)
                        setLogoImage(data)
                    },
                    (err) => {
                        console.log(err)
                    }
                )
            }
        },
        [logoImage]
    );

    return (
        <Box>
            <div className={classes.passwordChange} >
                <span></span>
                <div>
                    <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === true}>
                        <Fab
                            size="small"
                            color="primary"
                            className={classes.fab}
                            onClick={(event) => {
                                event.stopPropagation();
                                onSetEditMode(true)
                            }}
                        >
                            <EditRoundedIcon />
                        </Fab>
                    </Tooltip>

                    <Tooltip title="Cancel" aria-label="Cancel Changes" hidden={!editMode}>
                        <Fab size="small" color="primary" className={classes.fab} onClick={onCancelChanges}>
                            <CancelRoundedIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Edit" aria-label="Edit Item" hidden={editMode === false}>
                        <Fab size="small" color="primary" className={classes.fab} onClick={onSaveChanges}>
                            <SaveRoundedIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
            <div>
                {currentBusinessProfile &&
                    <BusinessProfileForm editMode={editMode} logoImage={logoImage} businessprofile={currentBusinessProfile} handleChangeEvent={handleChangeEvent}></BusinessProfileForm>
                }
            </div>
        </Box>

    )
}