import React from 'react';
import Image from './Image';
import Carousel from 'react-elastic-carousel';
import { makeStyles } from '@material-ui/core';
import { AppBar, Toolbar, Slide, Dialog, Button } from '@material-ui/core/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DeleteFile } from './DeleteFile';

const useStyles = makeStyles((theme) => ({
	itemstyle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '250px',
		width: '100%',
		backgroundColor: '#683bb7',
		color: '#fff',
		margin: '15px',
		fontSize: '4em'
	},
	appBar: {
		backgroundColor: '#9e9e9e'
	},
	deleteButton: {
		color: 'red'
	},
	toolbar: {
		'& > *': {
			margin: theme.spacing(1)
		}
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const prepareImageItems = (item) => {
	if (!item) return [];
	//console.log('prepareImageItems', ' called');
	let imageItems = [];
	if (item && item.images) {
		const imageObjects = Object.values(item.images);
		if (imageObjects) {
			for (const imageItem of imageObjects) {
				const imageUrl = '/image/' + item.rid + '/' + imageItem.src;
				imageItems.push({
					src: imageUrl,
					title: imageItem.title,
					id: imageItem.id
				});
			}
		}
	}

	//if the item is valid, but does not have any images
	//NOTE: the image data will be loaded from a localsource
	if (item && !imageItems.length) {
		imageItems.push({
			src: '',
			title: 'sample'
		});
	}

	return imageItems;
};

export const ImageCarousel = ({ item, extData }) => {
	//console.log('item inside ImageCarousel is ', item);
	const [selectedTile, setSelectedTile] = React.useState(null);
	const [imageItems, setImageItems] = React.useState(prepareImageItems(item));
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const handleClickOpen = (imageItem) => {
		//console.log(imageItem);
		setSelectedTile(imageItem);
	};

	const handleClose = () => {
		setSelectedTile(null);
	};

	const classes = useStyles();
	if (!imageItems) {
		return <div />;
	}
	const breakPoints = [
		{ width: 1, itemsToShow: 1 },
		{ width: 550, itemsToShow: 2, itemsToScroll: 2 },
		{ width: 768, itemsToShow: 3 },
		{ width: 1200, itemsToShow: 4 }
	];

	const onChange = () => {
		setImageItems(prepareImageItems(item));
	};

	let index = 0;
	//console.log('number of images available ', imageItems.length);
	return (
		<div>
			<Carousel breakPoints={breakPoints}>
				{imageItems.map((item) => {
					index++;
					return (
						<Image
							key={index}
							alt="image"
							imageItem={item}
							handleOnClick={handleClickOpen}
							clickable={true}
						/>
					);
				})}
			</Carousel>

			<Dialog
				fullScreen={fullScreen}
				open={selectedTile !== null}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar className={classes.toolbar}>
						<Button
							edge="start"
							color="primary"
							onClick={handleClose}
							aria-label="close"
							variant="contained"
						>
							Close
						</Button>
						{/* <Button aria-label="delete image" className={classes.deleteButton} variant="contained">
							Delete Image
						</Button> */}
						<DeleteFile
							item={item}
							extData={extData}
							mediaType="image"
							selectedFile={selectedTile}
							handleClose={handleClose}
							onChange={onChange}
							variant="contained"

						/>
					</Toolbar>
				</AppBar>

				{selectedTile && <Image alt="image" imageItem={selectedTile} clickable={false} />}
			</Dialog>
		</div>
	);
};
//https://github.com/sag1v/react-elastic-carousel
//https://codesandbox.io/s/github/logeshhy/Material-UI-ImageGallery?file=/src/Components/TitlebarGridList.jsx:325-335
