import { getUser, getUserEID, getUsername } from ".";

export function NewInventoryItemObject() {
    let newItem = {
        isnew: true,
        eid: getUserEID(),
        rid: '',
        title: '',
        description: '',
        details: {
            targetprice: {
                currency: '$'
            },
            purchaseprice: {
                currency: '$'
            }
        },
        notes: '',
        includedeleted: 0
    };

    return newItem
}

export function NewInvoiceObject(inventoryItem) {
    let newItem = {
        isnew: true,
        eid: getUserEID(),
        itemid: inventoryItem.rid,
        itemtitle: inventoryItem.title,
        username: getUsername(),
        approvedby: "",
        status: "",
        quotedprice: 0.0,
        taxpercent: 0.0,
        fees: 0.0,
        customerinfo: {
            name: "",
            company: "",
            email: "",
            phone: "",
            address: "",
            customernotes: ""
        },
        salesnotes: "",
        deleted: 0
    }

    return newItem
}

export function SanitizeInvoiceObject(invoice) {
    let modified = {...invoice} 
    modified.quotedprice = parseFloat(modified.quotedprice)
    modified.taxpercent = parseFloat(modified.taxpercent)
    modified.fees = parseFloat(modified.fees)
    return modified
}

export function NewInvoiceTransactionObject(invoice) {
    let balance = invoice.totalcost - invoice.totalpaid
    let sanitizedBalance = Number.parseFloat(balance).toFixed(2)
    let transaction = {
        isnew: true,
        invoiceid: invoice.id,
        //we will set the amountpaid to outstanding balance for ease of use
        amountpaid: sanitizedBalance,
        paidby: '',
        byuser: getUser().username,
        chargenotes: ''        
    }

    return transaction
}

//corrects the numeric types of data for use in sending it to a backend api which requires correct types
export function SanitizeInvoiceTransactionObject(transaction) {
    let modified = {...transaction} 
    modified.amountpaid = parseFloat(modified.amountpaid)
    return modified
}